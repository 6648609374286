import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from '@shared/services/user-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../../core/services/data.service';
import { PaginationControllerService } from '../../dashboard/services/pagination-controller.service';
import { ApiWrapperService } from '../api-services/api-wrapper.service';
import { LocalStorageKeys } from '../enums/local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private readonly router: Router,
        private readonly apiWrapperService: ApiWrapperService,
        private readonly paginationControllerService: PaginationControllerService,
        private dataService: DataService,
        private userDataService: UserDataService,
    ) {
        // nothing to do here yet
    }

    /**
     * Method to check whether the JWT token is expired
     * @param token
     */
    private static isJwtTokenValid(token: string): boolean {
        const expiry: number = +JSON.parse(window.atob(token.split('.')[1])).exp;

        return expiry >= Math.floor(new Date().getTime() / 1000);
    }

    /**
     * Method to set the Jwt token
     */
    private static setJwtToken(token: string): void {
        localStorage.setItem(LocalStorageKeys.JWT_TOKEN, token);
    }

    /**
     * Method to remove localStorage data
     */
    private static clearLocalStorage(): void {
        localStorage.clear();
    }

    /**
     * Method to log the user in
     * @param username
     * @param password
     */
    public login(username: string, password: string): Observable<string> {
        return this.apiWrapperService.loginPost(username, password).pipe(
            map(data => {
                const token: string = data.headers.get('x-token');
                if (data) {
                    this.userDataService.updateUserInfo(data.body);
                }
                if (token) {
                    AuthService.setJwtToken(token);
                }

                return token;
            }),
        );
    }

    /**
     * Method to log the user out
     */
    public logout(): void {
        this.userDataService.reset();
        AuthService.clearLocalStorage();
        this.paginationControllerService.clear();
        void this.router.navigate(['auth', 'login']);
    }

    /**
     * Method to check whether user is logged in
     */
    public isLoggedIn(): boolean {
        const token: string = this.getJwtToken();

        return token === null ? false : AuthService.isJwtTokenValid(token);
    }

    /**
     * Method to get the Jwt token
     */
    public getJwtToken(): string {
        return localStorage.getItem(LocalStorageKeys.JWT_TOKEN);
    }
}
