export const sentryErrorCodes: string[] = [
    'EMPLOYEE_DOES_NOT_EXIST',
    'COMPANY_LOCATION_DOES_NOT_EXIST',
    'ACCESS_TO_COMPANY_LOCATION_NOT_ALLOWED',
    'QUOTA_LIMIT_REACHED',
    'NO_EMPLOYEE_EXISTS',
    'COMPANY_LOCATION_OUT_OF_COVERED_AREA',
    'NOT_ENOUGH_CALCULATION_DATA',
    'MODAL_SPLIT_SUGGESTION_ERROR',
    'DETAILED_TRIP_TYPE_NOT_ALLOWED',
    'ENTRANCE_DOES_NOT_EXIST',
    'HIGHER_LEVEL_REQUIRED_SERVICE',
    'COMPANY_LOCATION_UPDATE_ERROR',
    'PACKAGE_UPDATE_ERROR',
    'PACKAGE_DOES_NOT_EXIST',
    'MOBILITY_OR_VEHICLE_STATS_CONSTRAINT_VIOLATION',
    'FEATURE_DISABLED',
    'ALL_EMPLOYEES_FAILED_GEOCODING',
    'DISTANCE_CALCULATION_ERROR',
    'USER_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES',
    'LOCATION_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES',
    'SERVICE_IS_NOT_ALLOWED_IN_THIS_AREA',
    'NO_MODES_ASSIGNABLE',
];
