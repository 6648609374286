import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable({
    providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {
        // nothing to do here
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // start loading
        this.loadingService.startLoading();

        return next.handle(req).pipe(
            finalize(() => {
                // stop loading on complete
                this.loadingService.endLoading();
            }),
        );
    }
}
