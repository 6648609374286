/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AggregatedEmployeeInput } from '../models/aggregated-employee-input';
import { AggregatedGeocodingResult } from '../models/aggregated-geocoding-result';
import { DetailedEmployeeInput } from '../models/detailed-employee-input';
import { DetailedGeocodingResult } from '../models/detailed-geocoding-result';
import { ValidationResult } from '../models/validation-result';

@Injectable({
  providedIn: 'root',
})
export class EmployeeUploadService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation validateFile
   */
  static readonly ValidateFilePath = '/api/v1/locations/{ignored}/employees/validate';

  /**
   * Validate an employee address file.
   *
   * Validates the headers of incoming excel and csv employee files.
   *             
   *             If the file is valid (i.e. when all required columns are included) a `200` status code will be returned. If 
   *             required columns are missing, a `400` status code will be returned.
   *             
   *             In both cases, the response always contains a `ValidationResult` entity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateFile$Response(params: {

    /**
     * If the uploaded file contains detailed or aggregated employee information
     */
    mode: 'DETAILED' | 'AGGREGATED';
    ignored: string;
    context?: HttpContext
    body?: {

/**
 * `CSV` or `Excel` file containing employee data. For more information see 
 *             [the docs](https://docs.mobility-audit.com/company-location/upload-employees). CSV files can be separated by 
 *             commas (`,`) and semicolons (`;`). The most likely used separator will be guessed based on the first line.
 *             
 *             All data is parsed based on column headers, so make sure your data fits the specification precisely.
 */
'file': Blob;
}
  }
): Observable<StrictHttpResponse<ValidationResult>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeUploadService.ValidateFilePath, 'post');
    if (params) {
      rb.query('mode', params.mode, {});
      rb.path('ignored', params.ignored, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidationResult>;
      })
    );
  }

  /**
   * Validate an employee address file.
   *
   * Validates the headers of incoming excel and csv employee files.
   *             
   *             If the file is valid (i.e. when all required columns are included) a `200` status code will be returned. If 
   *             required columns are missing, a `400` status code will be returned.
   *             
   *             In both cases, the response always contains a `ValidationResult` entity.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateFile(params: {

    /**
     * If the uploaded file contains detailed or aggregated employee information
     */
    mode: 'DETAILED' | 'AGGREGATED';
    ignored: string;
    context?: HttpContext
    body?: {

/**
 * `CSV` or `Excel` file containing employee data. For more information see 
 *             [the docs](https://docs.mobility-audit.com/company-location/upload-employees). CSV files can be separated by 
 *             commas (`,`) and semicolons (`;`). The most likely used separator will be guessed based on the first line.
 *             
 *             All data is parsed based on column headers, so make sure your data fits the specification precisely.
 */
'file': Blob;
}
  }
): Observable<ValidationResult> {

    return this.validateFile$Response(params).pipe(
      map((r: StrictHttpResponse<ValidationResult>) => r.body as ValidationResult)
    );
  }

  /**
   * Path part for operation uploadEmployees
   */
  static readonly UploadEmployeesPath = '/api/v1/locations/{companyLocationId}/employees/upload';

  /**
   * Upload an employee address file.
   *
   * Employees are read from the csv or Excel file posted. The file type is dependent on the ending 
   *             |of the posted file (i.e. `.csv`, `.xls` or `.xlsx`).
   *             |
   *             |If a distance calculation is included, the default values used in the distance calculation are applied. 
   *             |Note that this function checks if user limits are exceeded (i.e. employees per company location or covered
   *             |analysis area per user) and returns error codes accordingly.
   *             |
   *             |
   *             |Parameters marked as **internal** should only be enabled when users have debug permissions in the feature flags.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadEmployees()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadEmployees$Response(params: {
    companyLocationId: number;

    /**
     * _(internal)_ Geocoder to use for the employees
     */
    geocoder?: 'employeeGeocoder' | 'hereApi';

    /**
     * If the calculation results should be returned synchronously (default) or asynchronously. Keep in mind the response type is impacted on this.
     */
    async?: boolean;

    /**
     * If the uploaded file contains detailed or aggregated employee information
     */
    mode: 'DETAILED' | 'AGGREGATED';
    withDistanceCalculation?: boolean;

    /**
     * If unspecified entry and exit times should be set to the company- or server-wide default statically. In practice, this means that default times would be saved and not changed, even if server-wide defaults would be updated.
     */
    setEntryAndExitTime?: boolean;
    overwriteExistingEmployees?: boolean;

    /**
     * (_internal_) If a special parsing mode should be applied in order to parse additional data from the uploaded file
     */
    specialParsingMode?: string;

    /**
     * If geocoding should fall back on here api geocoder when geocoding with given geocoder fails. Can only be used when mode &#x3D; &#x60;DETAILED&#x60;
     */
    fallback?: boolean;

    /**
     * Allow filtering employees who&#x27;s distance (in meters) from the company is higher than the limit specified (default is 0.0 and means no limit)
     */
    limitDistanceFromCompanyLocation?: number;
    context?: HttpContext
    body?: {

/**
 * `CSV`, `Excel` or `JSON` file containing employee data. For more information see 
 *             [the docs](https://docs.mobility-audit.com/company-location/upload-employees). CSV files can be separated by 
 *             commas (`,`) and semicolons (`;`). The most likely used separator will be guessed based on the first line.
 *             
 *             All data is parsed based on column headers, so make sure your data fits the specification precisely.
 */
'file': Blob;
}
  }
): Observable<StrictHttpResponse<(AggregatedGeocodingResult | DetailedGeocodingResult)>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeUploadService.UploadEmployeesPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('geocoder', params.geocoder, {});
      rb.query('async', params.async, {});
      rb.query('mode', params.mode, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('setEntryAndExitTime', params.setEntryAndExitTime, {});
      rb.query('overwriteExistingEmployees', params.overwriteExistingEmployees, {});
      rb.query('specialParsingMode', params.specialParsingMode, {});
      rb.query('fallback', params.fallback, {});
      rb.query('limitDistanceFromCompanyLocation', params.limitDistanceFromCompanyLocation, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(AggregatedGeocodingResult | DetailedGeocodingResult)>;
      })
    );
  }

  /**
   * Upload an employee address file.
   *
   * Employees are read from the csv or Excel file posted. The file type is dependent on the ending 
   *             |of the posted file (i.e. `.csv`, `.xls` or `.xlsx`).
   *             |
   *             |If a distance calculation is included, the default values used in the distance calculation are applied. 
   *             |Note that this function checks if user limits are exceeded (i.e. employees per company location or covered
   *             |analysis area per user) and returns error codes accordingly.
   *             |
   *             |
   *             |Parameters marked as **internal** should only be enabled when users have debug permissions in the feature flags.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadEmployees$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadEmployees(params: {
    companyLocationId: number;

    /**
     * _(internal)_ Geocoder to use for the employees
     */
    geocoder?: 'employeeGeocoder' | 'hereApi';

    /**
     * If the calculation results should be returned synchronously (default) or asynchronously. Keep in mind the response type is impacted on this.
     */
    async?: boolean;

    /**
     * If the uploaded file contains detailed or aggregated employee information
     */
    mode: 'DETAILED' | 'AGGREGATED';
    withDistanceCalculation?: boolean;

    /**
     * If unspecified entry and exit times should be set to the company- or server-wide default statically. In practice, this means that default times would be saved and not changed, even if server-wide defaults would be updated.
     */
    setEntryAndExitTime?: boolean;
    overwriteExistingEmployees?: boolean;

    /**
     * (_internal_) If a special parsing mode should be applied in order to parse additional data from the uploaded file
     */
    specialParsingMode?: string;

    /**
     * If geocoding should fall back on here api geocoder when geocoding with given geocoder fails. Can only be used when mode &#x3D; &#x60;DETAILED&#x60;
     */
    fallback?: boolean;

    /**
     * Allow filtering employees who&#x27;s distance (in meters) from the company is higher than the limit specified (default is 0.0 and means no limit)
     */
    limitDistanceFromCompanyLocation?: number;
    context?: HttpContext
    body?: {

/**
 * `CSV`, `Excel` or `JSON` file containing employee data. For more information see 
 *             [the docs](https://docs.mobility-audit.com/company-location/upload-employees). CSV files can be separated by 
 *             commas (`,`) and semicolons (`;`). The most likely used separator will be guessed based on the first line.
 *             
 *             All data is parsed based on column headers, so make sure your data fits the specification precisely.
 */
'file': Blob;
}
  }
): Observable<(AggregatedGeocodingResult | DetailedGeocodingResult)> {

    return this.uploadEmployees$Response(params).pipe(
      map((r: StrictHttpResponse<(AggregatedGeocodingResult | DetailedGeocodingResult)>) => r.body as (AggregatedGeocodingResult | DetailedGeocodingResult))
    );
  }

  /**
   * Path part for operation uploadJsonEmployees
   */
  static readonly UploadJsonEmployeesPath = '/api/v1/locations/{companyLocationId}/employees/uploadJson';

  /**
   * Upload an employee address file.
   *
   * Employees are read from the csv or Excel file posted. The file type is dependent on the ending 
   *             |of the posted file (i.e. `.csv`, `.xls` or `.xlsx`).
   *             |
   *             |If a distance calculation is included, the default values used in the distance calculation are applied. 
   *             |Note that this function checks if user limits are exceeded (i.e. employees per company location or covered
   *             |analysis area per user) and returns error codes accordingly.
   *             |
   *             |
   *             |Parameters marked as **internal** should only be enabled when users have debug permissions in the feature flags.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadJsonEmployees()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadJsonEmployees$Response(params: {
    companyLocationId: number;

    /**
     * _(internal)_ Geocoder to use for the employees
     */
    geocoder?: 'employeeGeocoder' | 'hereApi';

    /**
     * If the calculation results should be returned synchronously (default) or asynchronously. Keep in mind the response type is impacted on this.
     */
    async?: boolean;

    /**
     * If the uploaded file contains detailed or aggregated employee information
     */
    mode: 'DETAILED' | 'AGGREGATED';
    withDistanceCalculation?: boolean;

    /**
     * If unspecified entry and exit times should be set to the company- or server-wide default statically. In practice, this means that default times would be saved and not changed, even if server-wide defaults would be updated.
     */
    setEntryAndExitTime?: boolean;
    overwriteExistingEmployees?: boolean;

    /**
     * (_internal_) If a special parsing mode should be applied in order to parse additional data from the uploaded file
     */
    specialParsingMode?: string;

    /**
     * If geocoding should fall back on here api geocoder when geocoding with given geocoder fails. Can only be used when mode &#x3D; &#x60;DETAILED&#x60;
     */
    fallback?: boolean;

    /**
     * Allow filtering employees who&#x27;s distance (in meters) from the company is higher than the limit specified (default is 0.0 and means no limit)
     */
    limitDistanceFromCompanyLocation?: number;
    context?: HttpContext
    body: Array<(AggregatedEmployeeInput | DetailedEmployeeInput)>
  }
): Observable<StrictHttpResponse<(AggregatedGeocodingResult | DetailedGeocodingResult)>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeUploadService.UploadJsonEmployeesPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('geocoder', params.geocoder, {});
      rb.query('async', params.async, {});
      rb.query('mode', params.mode, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('setEntryAndExitTime', params.setEntryAndExitTime, {});
      rb.query('overwriteExistingEmployees', params.overwriteExistingEmployees, {});
      rb.query('specialParsingMode', params.specialParsingMode, {});
      rb.query('fallback', params.fallback, {});
      rb.query('limitDistanceFromCompanyLocation', params.limitDistanceFromCompanyLocation, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(AggregatedGeocodingResult | DetailedGeocodingResult)>;
      })
    );
  }

  /**
   * Upload an employee address file.
   *
   * Employees are read from the csv or Excel file posted. The file type is dependent on the ending 
   *             |of the posted file (i.e. `.csv`, `.xls` or `.xlsx`).
   *             |
   *             |If a distance calculation is included, the default values used in the distance calculation are applied. 
   *             |Note that this function checks if user limits are exceeded (i.e. employees per company location or covered
   *             |analysis area per user) and returns error codes accordingly.
   *             |
   *             |
   *             |Parameters marked as **internal** should only be enabled when users have debug permissions in the feature flags.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadJsonEmployees$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadJsonEmployees(params: {
    companyLocationId: number;

    /**
     * _(internal)_ Geocoder to use for the employees
     */
    geocoder?: 'employeeGeocoder' | 'hereApi';

    /**
     * If the calculation results should be returned synchronously (default) or asynchronously. Keep in mind the response type is impacted on this.
     */
    async?: boolean;

    /**
     * If the uploaded file contains detailed or aggregated employee information
     */
    mode: 'DETAILED' | 'AGGREGATED';
    withDistanceCalculation?: boolean;

    /**
     * If unspecified entry and exit times should be set to the company- or server-wide default statically. In practice, this means that default times would be saved and not changed, even if server-wide defaults would be updated.
     */
    setEntryAndExitTime?: boolean;
    overwriteExistingEmployees?: boolean;

    /**
     * (_internal_) If a special parsing mode should be applied in order to parse additional data from the uploaded file
     */
    specialParsingMode?: string;

    /**
     * If geocoding should fall back on here api geocoder when geocoding with given geocoder fails. Can only be used when mode &#x3D; &#x60;DETAILED&#x60;
     */
    fallback?: boolean;

    /**
     * Allow filtering employees who&#x27;s distance (in meters) from the company is higher than the limit specified (default is 0.0 and means no limit)
     */
    limitDistanceFromCompanyLocation?: number;
    context?: HttpContext
    body: Array<(AggregatedEmployeeInput | DetailedEmployeeInput)>
  }
): Observable<(AggregatedGeocodingResult | DetailedGeocodingResult)> {

    return this.uploadJsonEmployees$Response(params).pipe(
      map((r: StrictHttpResponse<(AggregatedGeocodingResult | DetailedGeocodingResult)>) => r.body as (AggregatedGeocodingResult | DetailedGeocodingResult))
    );
  }

}
