/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FeatureFlag } from '../models/feature-flag';


/**
 * Internal API for setting and editing feature flags, check out the [debug UI here](/ff-debug-ui/index.html)
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateFlag
   */
  static readonly UpdateFlagPath = '/d/featureFlags/{flagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFlag$Response(params: {
    flagId: number;
    context?: HttpContext
    body: FeatureFlag
  }
): Observable<StrictHttpResponse<FeatureFlag>> {

    const rb = new RequestBuilder(this.rootUrl, FeatureFlagsService.UpdateFlagPath, 'put');
    if (params) {
      rb.path('flagId', params.flagId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FeatureFlag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFlag(params: {
    flagId: number;
    context?: HttpContext
    body: FeatureFlag
  }
): Observable<FeatureFlag> {

    return this.updateFlag$Response(params).pipe(
      map((r: StrictHttpResponse<FeatureFlag>) => r.body as FeatureFlag)
    );
  }

  /**
   * Path part for operation deleteFlag
   */
  static readonly DeleteFlagPath = '/d/featureFlags/{flagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFlag()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFlag$Response(params: {
    flagId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FeatureFlagsService.DeleteFlagPath, 'delete');
    if (params) {
      rb.path('flagId', params.flagId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFlag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFlag(params: {
    flagId: number;
    context?: HttpContext
  }
): Observable<{
}> {

    return this.deleteFlag$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getFlags
   */
  static readonly GetFlagsPath = '/d/featureFlags';

  /**
   * Get all feature flags for a specific owner.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlags$Response(params?: {

    /**
     * Owner Id to show feature flags for
     */
    ownerId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FeatureFlag>>> {

    const rb = new RequestBuilder(this.rootUrl, FeatureFlagsService.GetFlagsPath, 'get');
    if (params) {
      rb.query('ownerId', params.ownerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FeatureFlag>>;
      })
    );
  }

  /**
   * Get all feature flags for a specific owner.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFlags(params?: {

    /**
     * Owner Id to show feature flags for
     */
    ownerId?: number;
    context?: HttpContext
  }
): Observable<Array<FeatureFlag>> {

    return this.getFlags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FeatureFlag>>) => r.body as Array<FeatureFlag>)
    );
  }

  /**
   * Path part for operation createFlag
   */
  static readonly CreateFlagPath = '/d/featureFlags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlag$Response(params: {
    context?: HttpContext
    body: FeatureFlag
  }
): Observable<StrictHttpResponse<FeatureFlag>> {

    const rb = new RequestBuilder(this.rootUrl, FeatureFlagsService.CreateFlagPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FeatureFlag>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFlag(params: {
    context?: HttpContext
    body: FeatureFlag
  }
): Observable<FeatureFlag> {

    return this.createFlag$Response(params).pipe(
      map((r: StrictHttpResponse<FeatureFlag>) => r.body as FeatureFlag)
    );
  }

  /**
   * Path part for operation getGlobalFlags
   */
  static readonly GetGlobalFlagsPath = '/d/featureFlags/global';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGlobalFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalFlags$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FeatureFlag>>> {

    const rb = new RequestBuilder(this.rootUrl, FeatureFlagsService.GetGlobalFlagsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FeatureFlag>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGlobalFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGlobalFlags(params?: {
    context?: HttpContext
  }
): Observable<Array<FeatureFlag>> {

    return this.getGlobalFlags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FeatureFlag>>) => r.body as Array<FeatureFlag>)
    );
  }

}
