/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GeoJsonExample } from '../models/geo-json-example';
import { Trip } from '../models/trip';

@Injectable({
  providedIn: 'root',
})
export class TripsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTrips
   */
  static readonly GetTripsPath = '/api/v1/locations/{companyLocationId}/employees/{employeeId}/trips';

  /**
   * Get trips of a certain employee.
   *
   * fetch clustering / individual  trips of a giving employee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrips()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrips$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * employee id
     */
    employeeId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Trip>>> {

    const rb = new RequestBuilder(this.rootUrl, TripsService.GetTripsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Trip>>;
      })
    );
  }

  /**
   * Get trips of a certain employee.
   *
   * fetch clustering / individual  trips of a giving employee.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTrips$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrips(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * employee id
     */
    employeeId: number;
    context?: HttpContext
  }
): Observable<Array<Trip>> {

    return this.getTrips$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Trip>>) => r.body as Array<Trip>)
    );
  }

  /**
   * Path part for operation getTripsGeojson
   */
  static readonly GetTripsGeojsonPath = '/api/v1/locations/{companyLocationId}/employees/{employeeId}/trips.geojson';

  /**
   * Get trips of a certain employee as geojson.
   *
   * fetch clustering / individual  trips of a giving employee. Returns a featureCollection, where each feature is representing a trip.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTripsGeojson()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripsGeojson$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * employee id
     */
    employeeId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeoJsonExample>> {

    const rb = new RequestBuilder(this.rootUrl, TripsService.GetTripsGeojsonPath, 'get');
    if (params) {
      rb.query('companyLocationId', params.companyLocationId, {});
      rb.query('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeoJsonExample>;
      })
    );
  }

  /**
   * Get trips of a certain employee as geojson.
   *
   * fetch clustering / individual  trips of a giving employee. Returns a featureCollection, where each feature is representing a trip.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTripsGeojson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTripsGeojson(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * employee id
     */
    employeeId: number;
    context?: HttpContext
  }
): Observable<GeoJsonExample> {

    return this.getTripsGeojson$Response(params).pipe(
      map((r: StrictHttpResponse<GeoJsonExample>) => r.body as GeoJsonExample)
    );
  }

}
