import { Language } from '@shared/interfaces/language.interface';

export const languages: { [country: string]: Language } = {
    DE: {
        name: 'Deutsch',
        path: 'de',
        image: 'de',
    },
    EN: {
        name: 'English',
        path: 'en',
        image: 'gb',
    },
    FR: {
        name: 'France',
        path: 'fr',
        image: 'fr',
    },
};
