import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { ResolveUserDataService } from '@shared/services/resolve-user-data.service';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly resolveUserDataService: ResolveUserDataService,
    ) {
        // nothing to do here
    }

    /**
     * Determines whether to load auth protected lazy loaded modules depending on auth status
     * @param route
     * @param segments
     */
    public canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isLoggedIn: boolean = this.authService.isLoggedIn();
        if (isLoggedIn) {
            return this.resolveUserDataService.resolve().then(() => {
                return true;
            });
        }

        // can load isn't triggered on parent level thus autis is missing in url segements
        void this.router.navigate(['auth', 'login'], {
            queryParams: { redirect: `/audits/${segments.join('/')}` },
        });

        return false;
    }

    /**
     * Determines whether to activate auth protected
     * @param route
     * @param state
     */
    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._canActivate(route, state);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._canActivate(childRoute, state);
    }

    private _canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isLoggedIn: boolean = this.authService.isLoggedIn();
        if (isLoggedIn) {
            return this.resolveUserDataService.resolve().then(() => {
                return true;
            });
        }

        void this.router.navigate(['auth', 'login'], {
            queryParams: { redirect: state.url },
        });

        return false;
    }
}
