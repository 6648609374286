import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { configureScope } from '@sentry/angular-ivy';
import { ApiWrapperService } from '@shared/api-services/api-wrapper.service';
import { AuthService } from '@shared/services/auth.service';
import { UserDataService } from '@shared/services/user-data.service';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResolveUserDataService {
    private hasResolved = false;

    constructor(
        private readonly router: Router,
        private apiWrapperService: ApiWrapperService,
        private readonly userDataService: UserDataService,
        private readonly authService: AuthService,
    ) {}

    /**
     * resolve route
     */
    public resolve(): Promise<void> {
        return new Promise(resolve => {
            if (this.hasResolved) {
                resolve();

                return;
            }

            this.apiWrapperService
                .getUserInfo()
                .pipe(take(1))
                .subscribe({
                    next: userInfo => {
                        this.userDataService.updateUserInfo(userInfo);
                        this.hasResolved = true;
                        configureScope(scope => {
                            scope.setUser({
                                id: userInfo.id.toString(),
                                username: userInfo.name,
                                email: userInfo.email,
                            });
                        });
                        resolve();
                    },
                    error: () => {
                        this.authService.logout();
                        void this.router.navigate(['auth', 'login']);
                        resolve();
                    },
                });
        });
    }
}
