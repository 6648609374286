import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyLocationsWrapperService } from '@shared/api-services/company-locations-wrapper.service';
import { EmployeesWrapperService } from '@shared/api-services/employees-wrapper.service';
import { MobilityPackagesWrapperService } from '@shared/api-services/mobility-packages-wrapper.service';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import { OptimalCatchment } from '@shared/interfaces/optimal-catchment-response.interface';
import { UserDataService } from '@shared/services/user-data.service';
import { UtilsService } from '@shared/services/utils.service';
import {
    BehaviorSubject,
    EmptyError,
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    firstValueFrom,
} from 'rxjs';
import { filter, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import {
    AggregatedEstimationQuality,
    CalculationProperties,
    CatchmentGeoJson,
    DepartmentKpi,
    EmployeeIdAndLocationAccuracy,
    EmployeePackageMode,
    Entrance,
    MinimalMobilityScore,
    MobilityScore,
    Package,
    PackageResponse,
} from 'src/app/api/models';
import { CompanyLocation } from '../../api/models/company-location';
import { Employee } from '../../api/models/employee';
import { Location } from '../../api/models/location';
import { MobilityPropertiesService } from '../../api/services/mobility-properties.service';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    public blockStreams$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public resetData$: Subject<void> = new Subject();

    public unsubscribe$: Subject<void> = new Subject();

    public companyLocation$: BehaviorSubject<CompanyLocation> =
        new BehaviorSubject<CompanyLocation>(null);
    public mobilityScoreError$: BehaviorSubject<HttpErrorResponse> =
        new BehaviorSubject<HttpErrorResponse>(null);

    public currentMobilityScore$: BehaviorSubject<MinimalMobilityScore | MobilityScore> =
        new BehaviorSubject<MinimalMobilityScore | MobilityScore>(null);
    public currentDepartmentKpi$: BehaviorSubject<DepartmentKpi[]> = new BehaviorSubject<
        DepartmentKpi[]
    >(null);
    public optimalDepartmentKpi$: BehaviorSubject<DepartmentKpi[]> = new BehaviorSubject<
        DepartmentKpi[]
    >(null);
    public optimumMobilityScore$: BehaviorSubject<MinimalMobilityScore | MobilityScore> =
        new BehaviorSubject(null);
    public selectedPackagesResponse$: BehaviorSubject<PackageResponse> =
        new BehaviorSubject<PackageResponse>(null);

    public packagesResponse$: BehaviorSubject<PackageResponse[]> = new BehaviorSubject<
        PackageResponse[]
    >([]);
    public departments$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    public selectedDepartment$: BehaviorSubject<string> = new BehaviorSubject(null);
    public selectedEntrances$: BehaviorSubject<Entrance[]> = new BehaviorSubject(null);

    public presenceDays$: ReplaySubject<number> = new ReplaySubject(1);

    public _employees$: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>(null);

    // mobilityScore currently visible also departmentKpi
    public mobilityScore$: BehaviorSubject<MobilityScore> = new BehaviorSubject<MobilityScore>(
        null,
    );

    // mobilityScore to compare currently visible also departmentKpi
    public mobilityScoreComparison$: BehaviorSubject<MobilityScore> =
        new BehaviorSubject<MobilityScore>(null);

    public locations$: Observable<Location[]> = this.selectedEntrances$
        .notNull()
        .pipe(map(entrance => entrance.map(ele => ele.location)));

    public selectedPackage$: Observable<Package> = this.selectedPackagesResponse$
        .notNull()
        .pipe(map(pRes => pRes.package_));

    public packages$: Observable<Package[]> = this.packagesResponse$.pipe(
        map((pRes: PackageResponse[]) => pRes.map((p: PackageResponse) => p.package_)),
    );

    public entrances$: Observable<Entrance[]> = this.companyLocation$.pipe(map(c => c.entrances));

    public selectedEmployeePackageModes$: Observable<EmployeePackageMode[]> = combineLatest([
        this.selectedPackage$.notNull().pipe(map(ele => ele.employeePackageModes)),
        this.selectedEntrances$,
    ]).pipe(
        map(([selectedPackageEmployees, entrance]) =>
            entrance.length > 1
                ? selectedPackageEmployees
                : selectedPackageEmployees.filter(
                      emp => emp.employee.entranceNumber === entrance[0].number,
                  ),
        ),
    );

    public employees$: Observable<Employee[]> = combineLatest([
        this._employees$,
        this.selectedDepartment$,
        this.selectedEntrances$,
    ]).pipe(
        filter(([employees, dept, entrance]) => employees != null),
        map(([employees, dept, entrance]) => {
            if (dept === null || dept === 'All') {
                return entrance.length > 1
                    ? employees
                    : employees.filter(emp => emp.entranceNumber === entrance[0].number);
            }

            // If Dept is not null or 'All'
            return entrance.length > 1
                ? employees.filter(emp => emp.department === dept)
                : employees.filter(
                      emp => emp.department === dept && emp.entranceNumber === entrance[0].number,
                  );
        }),
    );

    public geocodedEmployeeAccuracy: BehaviorSubject<EmployeeIdAndLocationAccuracy[]> =
        new BehaviorSubject<EmployeeIdAndLocationAccuracy[]>([]);
    public geocodedAggregatedAccuracy: BehaviorSubject<AggregatedEstimationQuality[]> =
        new BehaviorSubject<AggregatedEstimationQuality[]>([]);
    public userProperties: BehaviorSubject<CalculationProperties> = new BehaviorSubject(null);
    public companyProperties: BehaviorSubject<CalculationProperties> = new BehaviorSubject(null);

    private readonly _optimalCatchmentsCache: Map<string, Observable<OptimalCatchment>> = new Map<
        string,
        Observable<OptimalCatchment>
    >();
    private readonly _statusQuoCatchmentsCache: Map<string, Observable<CatchmentGeoJson>> = new Map<
        string,
        Observable<CatchmentGeoJson>
    >();

    constructor(
        private readonly utilsService: UtilsService,
        private readonly employeesWrapperService: EmployeesWrapperService,
        private readonly mobilityPackagesWrapperService: MobilityPackagesWrapperService,
        private readonly mobilityPropertiesService: MobilityPropertiesService,
        private readonly companyLocationsWrapperService: CompanyLocationsWrapperService,
        private readonly userDataService: UserDataService,
    ) {}

    /**
     * Update the company location
     * @param companyLocation
     */
    public updateCompanyLocation(companyLocation: CompanyLocation): void {
        this.companyLocation$.next(companyLocation);
    }

    /**
     * Resets all values which belongs to the current location except the location and entrances
     */
    public resetLocationDeps(): void {
        this._employees$.next(null);

        this.mobilityScore$.next(null);
        this.mobilityScoreComparison$.next(null);

        this.currentMobilityScore$.next(null);
        this.optimumMobilityScore$.next(null);

        this.optimalDepartmentKpi$.next([]);
        this.currentDepartmentKpi$.next([]);

        this.selectedDepartment$.next(null);

        this.selectedPackagesResponse$.next(null);
        this.packagesResponse$.next([]);

        this.selectedEntrances$.next(null);
        this.departments$.next([]);
        this._statusQuoCatchmentsCache.clear();
        this._optimalCatchmentsCache.clear();

        this.geocodedAggregatedAccuracy.next([]);
        this.geocodedEmployeeAccuracy.next([]);

        this.userProperties.next(null);
        this.companyProperties.next(null);
    }

    /**
     * Resets this service
     */
    public clear(): void {
        this.resetData$.next();

        this.companyLocation$.next(null);

        this.resetLocationDeps();
    }

    public async getEmployeeLocations(locationId: number): Promise<Employee[]> {
        const employees: Employee[] = await firstValueFrom(
            this.employeesWrapperService
                .getEmployeeLocations(locationId)
                .pipe(takeUntil(this.resetData$), takeUntil(this.unsubscribe$)),
        );
        const depts: string[] = [
            ...new Set(
                employees
                    .filter(it => it.department && it.department.trim().length > 0)
                    .map(item => item.department),
            ),
        ];
        this.departments$.next(depts);
        this._employees$.next(employees);

        return employees;
    }

    public async getMobilityPackages(showScenario) {
        const packages = await firstValueFrom(
            this.mobilityPackagesWrapperService
                .getMobilityPackages(this.companyLocation$.value.id)
                .pipe(takeUntil(this.unsubscribe$)),
        );

        if (!packages || packages.length === 0) {
            return;
        }
        this.packagesResponse$.next(packages);
        if (showScenario != 0) {
            this.selectedPackagesResponse$.next(packages.find(p => p.package_.id == showScenario));
        }
    }

    public async getMobilityScore(forceRecalculation: string | boolean = null): Promise<void> {
        const isPremiumUser = this.userDataService.isPremiumUser();
        const isViewOnlyUser = this.userDataService.isViewOnlyUser();

        const mobilityScoreRequest: Observable<{
            current?: MinimalMobilityScore | MobilityScore;
            optimal?: MinimalMobilityScore | MobilityScore;
            currentDepartmentKpis?: DepartmentKpi[];
            optimalDepartmentKpis?: DepartmentKpi[];
        }> =
            isPremiumUser || isViewOnlyUser
                ? this.companyLocationsWrapperService.getMobilityScore(
                      this.companyLocation$.value.id,
                      forceRecalculation === 'distance',
                      forceRecalculation === 'score',
                  )
                : this.companyLocationsWrapperService.getMinimalMobilityScore(
                      this.companyLocation$.value.id,
                      forceRecalculation === 'distance',
                      forceRecalculation === 'score',
                  );

        try {
            let score = await firstValueFrom(
                mobilityScoreRequest.pipe(takeUntil(this.resetData$), takeUntil(this.unsubscribe$)),
            );

            // @ts-expect-error: Typing issue
            score = await firstValueFrom(this.bugfix_displayableMobilityStats(score));

            this.mobilityScoreError$.next(null);
            score.current.mobilityStats = this.companyLocation$.value.displayableMobilityStats;
            this.currentMobilityScore$.next(score.current);
            this.optimumMobilityScore$.next(score.optimal);
            this.currentDepartmentKpi$.next(score.currentDepartmentKpis);
            this.optimalDepartmentKpi$.next(score.optimalDepartmentKpis);
            this.presenceDays$.next(score.current.presenceDays);
        } catch (e) {
            if (e instanceof EmptyError) {
                throw e;
            }
            this.mobilityScoreError$.next(e);
            this.currentDepartmentKpi$.next([]);
            this.optimalDepartmentKpi$.next([]);
            this.currentMobilityScore$.next(null);
            this.optimumMobilityScore$.next(null);
        }
    }

    public getOptimalCatchment(
        locationId: number,
        entranceNumber = 0,
    ): Observable<OptimalCatchment> {
        const key = `${entranceNumber}`;
        if (!this._optimalCatchmentsCache.get(key)) {
            this._optimalCatchmentsCache.set(
                key,
                this.companyLocationsWrapperService
                    .getOptimalCatchment(locationId, entranceNumber)
                    .pipe(takeUntil(this.resetData$), takeUntil(this.unsubscribe$), shareReplay(1)),
            );
        }

        return this._optimalCatchmentsCache.get(key);
    }

    public getCatchmentArea(
        companyLocationId: number,
        mode: 'bike' | 'car' | 'transit' | 'walk',
        time: number,
        entranceNumber = 0,
    ): Observable<CatchmentGeoJson> {
        // TODO: doesnt work if multiple times are given
        // caching needs to be adapted for that
        // also all subscribers need to be written to accept multiple times
        const cacheKeyString: string = mode + entranceNumber + time;
        const cacheKey = `${cacheKeyString}`;

        if (!this._statusQuoCatchmentsCache.get(cacheKey)) {
            this._statusQuoCatchmentsCache.set(
                cacheKey,
                this.companyLocationsWrapperService
                    .getCatchmentArea({
                        companyLocationId,
                        mode,
                        times: [time],
                        entranceNumber,
                    })
                    .pipe(takeUntil(this.resetData$), takeUntil(this.unsubscribe$), shareReplay(1)),
            );
        }

        return this._statusQuoCatchmentsCache.get(cacheKey);
    }

    public handlePromiseResult(
        promiseResult: PromiseSettledResult<unknown>,
        subject: Subject<unknown>,
    ) {
        if (promiseResult.status === 'fulfilled') {
            subject.next(promiseResult.value);
        } else if (promiseResult.reason instanceof EmptyError) {
            throw promiseResult.reason;
        }
    }

    /**
     * Get the user and location calculation properties and publish them to the respective subjects.
     *
     * Note that this method returns without calling the API if the `CALCULATION_PROPERTIES` feature flag is not enabled.
     */
    public async getUserAndLocationCalculationProperties(): Promise<void> {
        const canConfigureCalculationProperties = await firstValueFrom(
            this.userDataService.showFeature$(FeatureFlag.CALCULATION_PROPERTIES),
        );

        if (!canConfigureCalculationProperties) {
            return;
        }

        const [userMobilityProperties, companyMobilityProperties] = await Promise.allSettled([
            firstValueFrom(
                this.mobilityPropertiesService
                    .getUserMobilityProperties()
                    .pipe(takeUntil(this.unsubscribe$)),
            ),
            firstValueFrom(
                this.mobilityPropertiesService
                    .getLocationMobilityProperties({
                        companyLocationId: this.companyLocation$.value.id,
                    })
                    .pipe(takeUntil(this.unsubscribe$)),
            ),
        ]);

        this.handlePromiseResult(userMobilityProperties, this.userProperties);
        this.handlePromiseResult(companyMobilityProperties, this.companyProperties);
    }

    public async getGeocodingEstimation(): Promise<void> {
        if (this.userDataService.showFeature(FeatureFlag.GEOCODING_QUALITY)) {
            const [employeeGeocodingAcc, aggregatedGeocodingAcc] = await Promise.allSettled([
                firstValueFrom(
                    this.employeesWrapperService
                        .getDetailedEstimationQuality(this.companyLocation$.value.id)
                        .pipe(takeUntil(this.unsubscribe$)),
                ),
                firstValueFrom(
                    this.employeesWrapperService
                        .getAggregatedEstimationQuality(this.companyLocation$.value.id)
                        .pipe(takeUntil(this.unsubscribe$)),
                ),
            ]);

            this.handlePromiseResult(employeeGeocodingAcc, this.geocodedEmployeeAccuracy);
            this.handlePromiseResult(aggregatedGeocodingAcc, this.geocodedAggregatedAccuracy);
        }
    }

    /**
     * Resets data:
     * 1) blocks existing streams temporarily
     * 1) informs the application that we reset data
     * 2) unblocks existing streams
     */
    public resetData(): Observable<void> {
        return new Observable(subscriber => {
            this.blockStreams$.next(true);
            this.resetData$.next();
            this.utilsService.customWait(0, () => {
                this.blockStreams$.next(false);
                subscriber.next();
            });
        });
    }

    /**
     * https://triply.atlassian.net/browse/TPS-1185
     */
    private bugfix_displayableMobilityStats(score: {
        current: MobilityScore;
        optimal: MobilityScore;
    }): Observable<{ optimal: MobilityScore; current: MobilityScore }> {
        return this.companyLocationsWrapperService
            .getCompanyLocation(this.companyLocation$.value.id)
            .pipe(
                tap((companyLocation: CompanyLocation) => {
                    this.companyLocation$.value.displayableMobilityStats =
                        companyLocation.displayableMobilityStats;
                }),
                map(() => {
                    return score;
                }),
            );
    }
}
