import { Environment } from './interfaces/environment.interface';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
    production: false,
    apiUrl: 'https://upscore-staging.a.triply.ai',
    sentryDsn:
        'https://7326aa2175ca44d3ab6a137ef04743d4@o4504484883595264.ingest.sentry.io/4504484889231360',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
