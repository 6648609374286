import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { AuthGuard } from '@shared/guards/auth.guard';
import { LoginGuard } from '@shared/guards/login.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canLoad: [LoginGuard],
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'audits',
    },
    {
        path: 'audits',
        canActivateChild: [AuthGuard],
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                canLoad: [AuthGuard], // can load isn't triggered on parent level
                loadChildren: () =>
                    import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: '',
                canLoad: [AuthGuard], // can load isn't triggered on parent level
                loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
            },
        ],
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
