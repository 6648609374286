/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GjFeatureCollectionLatestCp } from '../models/gj-feature-collection-latest-cp';
import { SimulatedCarpooling } from '../models/simulated-carpooling';

@Injectable({
  providedIn: 'root',
})
export class CarpoolingDebugControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLatestCarpoolingResults
   */
  static readonly GetLatestCarpoolingResultsPath = '/debug/cl/{companyLocationId}/{accessCode}/latestCp.geojson';

  /**
   * get latest carpooling results for company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestCarpoolingResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestCarpoolingResults$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GjFeatureCollectionLatestCp>>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.GetLatestCarpoolingResultsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GjFeatureCollectionLatestCp>>;
      })
    );
  }

  /**
   * get latest carpooling results for company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestCarpoolingResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestCarpoolingResults(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<Array<GjFeatureCollectionLatestCp>> {

    return this.getLatestCarpoolingResults$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GjFeatureCollectionLatestCp>>) => r.body as Array<GjFeatureCollectionLatestCp>)
    );
  }

  /**
   * Path part for operation getLatestCarpoolingResults1
   */
  static readonly GetLatestCarpoolingResults1Path = '/d/cl/{companyLocationId}/{accessCode}/latestCp.geojson';

  /**
   * get latest carpooling results for company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestCarpoolingResults1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestCarpoolingResults1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GjFeatureCollectionLatestCp>>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.GetLatestCarpoolingResults1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GjFeatureCollectionLatestCp>>;
      })
    );
  }

  /**
   * get latest carpooling results for company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLatestCarpoolingResults1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestCarpoolingResults1(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<Array<GjFeatureCollectionLatestCp>> {

    return this.getLatestCarpoolingResults1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GjFeatureCollectionLatestCp>>) => r.body as Array<GjFeatureCollectionLatestCp>)
    );
  }

  /**
   * Path part for operation viewLatestCpResults
   */
  static readonly ViewLatestCpResultsPath = '/d/cl/{companyLocationId}/latestCp';

  /**
   * show latest carpooling results for company location in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewLatestCpResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewLatestCpResults$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.ViewLatestCpResultsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show latest carpooling results for company location in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewLatestCpResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewLatestCpResults(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.viewLatestCpResults$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation viewLatestCpResults1
   */
  static readonly ViewLatestCpResults1Path = '/debug/cl/{companyLocationId}/latestCp';

  /**
   * show latest carpooling results for company location in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewLatestCpResults1()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewLatestCpResults1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.ViewLatestCpResults1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show latest carpooling results for company location in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewLatestCpResults1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  viewLatestCpResults1(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.viewLatestCpResults1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation simulateCarpooling
   */
  static readonly SimulateCarpoolingPath = '/d/cl/{companyLocationId}/cp';

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulateCarpooling()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SimulatedCarpooling>>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.SimulateCarpoolingPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('carpools', params.carpools, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimulatedCarpooling>>;
      })
    );
  }

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `simulateCarpooling$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<Array<SimulatedCarpooling>> {

    return this.simulateCarpooling$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimulatedCarpooling>>) => r.body as Array<SimulatedCarpooling>)
    );
  }

  /**
   * Path part for operation simulateCarpooling1
   */
  static readonly SimulateCarpooling1Path = '/debug/cl/{companyLocationId}/carpooling';

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulateCarpooling1()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SimulatedCarpooling>>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.SimulateCarpooling1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('carpools', params.carpools, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimulatedCarpooling>>;
      })
    );
  }

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `simulateCarpooling1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling1(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<Array<SimulatedCarpooling>> {

    return this.simulateCarpooling1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimulatedCarpooling>>) => r.body as Array<SimulatedCarpooling>)
    );
  }

  /**
   * Path part for operation simulateCarpooling2
   */
  static readonly SimulateCarpooling2Path = '/debug/cl/{companyLocationId}/cp';

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulateCarpooling2()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling2$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SimulatedCarpooling>>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.SimulateCarpooling2Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('carpools', params.carpools, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimulatedCarpooling>>;
      })
    );
  }

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `simulateCarpooling2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling2(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<Array<SimulatedCarpooling>> {

    return this.simulateCarpooling2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimulatedCarpooling>>) => r.body as Array<SimulatedCarpooling>)
    );
  }

  /**
   * Path part for operation simulateCarpooling3
   */
  static readonly SimulateCarpooling3Path = '/d/cl/{companyLocationId}/carpooling';

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulateCarpooling3()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling3$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<SimulatedCarpooling>>> {

    const rb = new RequestBuilder(this.rootUrl, CarpoolingDebugControllerImplService.SimulateCarpooling3Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('carpools', params.carpools, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimulatedCarpooling>>;
      })
    );
  }

  /**
   * Simulate carpooling for company location employees.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `simulateCarpooling3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  simulateCarpooling3(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * number f carpools
     */
    carpools?: number;
    context?: HttpContext
  }
): Observable<Array<SimulatedCarpooling>> {

    return this.simulateCarpooling3$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimulatedCarpooling>>) => r.body as Array<SimulatedCarpooling>)
    );
  }

}
