/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CatchmentGeoJson } from '../models/catchment-geo-json';

@Injectable({
  providedIn: 'root',
})
export class ServiceAreaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getIntersectionBetweenServiceAreaAndCatchment
   */
  static readonly GetIntersectionBetweenServiceAreaAndCatchmentPath = '/d/{companyLocationId}/serviceAreaIntersection';

  /**
   * Calculate the intersection between a transportation mode's service Area and the catchment area of the same mode.
   *
   * get a geojson containing the intersection between the catchment area (with multiple catchment times) of a transportation mode (e.g: car) and service area of the same mode (e.g: taxi).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntersectionBetweenServiceAreaAndCatchment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIntersectionBetweenServiceAreaAndCatchment$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode
     */
    mode: string;
    times: Array<number>;

    /**
     * company location entrance number
     */
    entranceNumber?: number;
    context?: HttpContext
    body: Array<string>
  }
): Observable<StrictHttpResponse<CatchmentGeoJson>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceAreaService.GetIntersectionBetweenServiceAreaAndCatchmentPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('mode', params.mode, {});
      rb.query('times', params.times, {});
      rb.query('entranceNumber', params.entranceNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatchmentGeoJson>;
      })
    );
  }

  /**
   * Calculate the intersection between a transportation mode's service Area and the catchment area of the same mode.
   *
   * get a geojson containing the intersection between the catchment area (with multiple catchment times) of a transportation mode (e.g: car) and service area of the same mode (e.g: taxi).
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIntersectionBetweenServiceAreaAndCatchment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIntersectionBetweenServiceAreaAndCatchment(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode
     */
    mode: string;
    times: Array<number>;

    /**
     * company location entrance number
     */
    entranceNumber?: number;
    context?: HttpContext
    body: Array<string>
  }
): Observable<CatchmentGeoJson> {

    return this.getIntersectionBetweenServiceAreaAndCatchment$Response(params).pipe(
      map((r: StrictHttpResponse<CatchmentGeoJson>) => r.body as CatchmentGeoJson)
    );
  }

  /**
   * Path part for operation getIntersectionBetweenServiceAreaAndCatchment1
   */
  static readonly GetIntersectionBetweenServiceAreaAndCatchment1Path = '/debug/{companyLocationId}/serviceAreaIntersection';

  /**
   * Calculate the intersection between a transportation mode's service Area and the catchment area of the same mode.
   *
   * get a geojson containing the intersection between the catchment area (with multiple catchment times) of a transportation mode (e.g: car) and service area of the same mode (e.g: taxi).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntersectionBetweenServiceAreaAndCatchment1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIntersectionBetweenServiceAreaAndCatchment1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode
     */
    mode: string;
    times: Array<number>;

    /**
     * company location entrance number
     */
    entranceNumber?: number;
    context?: HttpContext
    body: Array<string>
  }
): Observable<StrictHttpResponse<CatchmentGeoJson>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceAreaService.GetIntersectionBetweenServiceAreaAndCatchment1Path, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('mode', params.mode, {});
      rb.query('times', params.times, {});
      rb.query('entranceNumber', params.entranceNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatchmentGeoJson>;
      })
    );
  }

  /**
   * Calculate the intersection between a transportation mode's service Area and the catchment area of the same mode.
   *
   * get a geojson containing the intersection between the catchment area (with multiple catchment times) of a transportation mode (e.g: car) and service area of the same mode (e.g: taxi).
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIntersectionBetweenServiceAreaAndCatchment1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getIntersectionBetweenServiceAreaAndCatchment1(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode
     */
    mode: string;
    times: Array<number>;

    /**
     * company location entrance number
     */
    entranceNumber?: number;
    context?: HttpContext
    body: Array<string>
  }
): Observable<CatchmentGeoJson> {

    return this.getIntersectionBetweenServiceAreaAndCatchment1$Response(params).pipe(
      map((r: StrictHttpResponse<CatchmentGeoJson>) => r.body as CatchmentGeoJson)
    );
  }

}
