/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class UserControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserArea
   */
  static readonly GetUserAreaPath = '/debug/user/{userId}/{accessCode}/area.geojson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserArea()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserArea$Response(params: {
    userId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetUserAreaPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserArea$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserArea(params: {
    userId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getUserArea$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getUserArea1
   */
  static readonly GetUserArea1Path = '/d/user/{userId}/{accessCode}/area.geojson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserArea1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserArea1$Response(params: {
    userId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetUserArea1Path, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserArea1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserArea1(params: {
    userId: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getUserArea1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getExternalServerMap
   */
  static readonly GetExternalServerMapPath = '/debug/externalServers/{routerIndex}/{accessCode}/area.geojson';

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalServerMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap$Response(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetExternalServerMapPath, 'get');
    if (params) {
      rb.path('routerIndex', params.routerIndex, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExternalServerMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getExternalServerMap$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getExternalServerMap1
   */
  static readonly GetExternalServerMap1Path = '/d/es/{routerIndex}/{accessCode}/area.geojson';

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalServerMap1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap1$Response(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetExternalServerMap1Path, 'get');
    if (params) {
      rb.path('routerIndex', params.routerIndex, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExternalServerMap1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap1(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getExternalServerMap1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getExternalServerMap2
   */
  static readonly GetExternalServerMap2Path = '/d/externalServers/{routerIndex}/{accessCode}/area.geojson';

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalServerMap2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap2$Response(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetExternalServerMap2Path, 'get');
    if (params) {
      rb.path('routerIndex', params.routerIndex, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExternalServerMap2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap2(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getExternalServerMap2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getExternalServerMap3
   */
  static readonly GetExternalServerMap3Path = '/debug/es/{routerIndex}/{accessCode}/area.geojson';

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalServerMap3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap3$Response(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetExternalServerMap3Path, 'get');
    if (params) {
      rb.path('routerIndex', params.routerIndex, {});
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get external server map for a specific router.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExternalServerMap3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalServerMap3(params: {
    routerIndex: number;
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getExternalServerMap3$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAllExternalServerMap
   */
  static readonly GetAllExternalServerMapPath = '/d/es/{accessCode}/allRoutersAreas.geojson';

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExternalServerMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetAllExternalServerMapPath, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllExternalServerMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getAllExternalServerMap$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAllExternalServerMap1
   */
  static readonly GetAllExternalServerMap1Path = '/debug/externalServers/{accessCode}/allRoutersAreas.geojson';

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExternalServerMap1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap1$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetAllExternalServerMap1Path, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllExternalServerMap1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap1(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getAllExternalServerMap1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAllExternalServerMap2
   */
  static readonly GetAllExternalServerMap2Path = '/d/externalServers/{accessCode}/allRoutersAreas.geojson';

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExternalServerMap2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap2$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetAllExternalServerMap2Path, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllExternalServerMap2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap2(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getAllExternalServerMap2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAllExternalServerMap3
   */
  static readonly GetAllExternalServerMap3Path = '/debug/es/{accessCode}/allRoutersAreas.geojson';

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllExternalServerMap3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap3$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UserControllerService.GetAllExternalServerMap3Path, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get all external server maps.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllExternalServerMap3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllExternalServerMap3(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.getAllExternalServerMap3$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
