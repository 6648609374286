/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CatchmentGeoJson } from '../models/catchment-geo-json';
import { CompanyLocation } from '../models/company-location';
import { CompanyLocationToChange } from '../models/company-location-to-change';
import { MinimalMobilityScoreResponse } from '../models/minimal-mobility-score-response';
import { MobilityScoreResponse } from '../models/mobility-score-response';
import { MobilityStats } from '../models/mobility-stats';
import { OptimalCatchmentResponse } from '../models/optimal-catchment-response';
import { PostedCompanyLocation } from '../models/posted-company-location';
import { TripDurations } from '../models/trip-durations';

@Injectable({
  providedIn: 'root',
})
export class CompanyLocationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation allCompanyLocations
   */
  static readonly AllCompanyLocationsPath = '/api/v1/locations';

  /**
   * List all company locations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allCompanyLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  allCompanyLocations$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CompanyLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.AllCompanyLocationsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyLocation>>;
      })
    );
  }

  /**
   * List all company locations.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allCompanyLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allCompanyLocations(params?: {
    context?: HttpContext
  }
): Observable<Array<CompanyLocation>> {

    return this.allCompanyLocations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyLocation>>) => r.body as Array<CompanyLocation>)
    );
  }

  /**
   * Path part for operation newCompanyLocation
   */
  static readonly NewCompanyLocationPath = '/api/v1/locations';

  /**
   * create company location.
   *
   * Mobility statistics (modal split + car utilization) and vehicleStats are set to sensible defaults whenever a new company is created and these objects aren't specified. Both Modal split and vehicleStat values are given as percentages and must sum up to 1.This defaults can either be set through a per-country pre-defined dataset or on a more granular level (e.g. per city) in the future.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyLocation$Response(params: {
    context?: HttpContext
    body: PostedCompanyLocation
  }
): Observable<StrictHttpResponse<CompanyLocation>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.NewCompanyLocationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocation>;
      })
    );
  }

  /**
   * create company location.
   *
   * Mobility statistics (modal split + car utilization) and vehicleStats are set to sensible defaults whenever a new company is created and these objects aren't specified. Both Modal split and vehicleStat values are given as percentages and must sum up to 1.This defaults can either be set through a per-country pre-defined dataset or on a more granular level (e.g. per city) in the future.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newCompanyLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyLocation(params: {
    context?: HttpContext
    body: PostedCompanyLocation
  }
): Observable<CompanyLocation> {

    return this.newCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocation>) => r.body as CompanyLocation)
    );
  }

  /**
   * Path part for operation companyLocation
   */
  static readonly CompanyLocationPath = '/api/v1/locations/{companyLocationId}';

  /**
   * Get information about a company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocation$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyLocation>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocation>;
      })
    );
  }

  /**
   * Get information about a company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocation(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<CompanyLocation> {

    return this.companyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocation>) => r.body as CompanyLocation)
    );
  }

  /**
   * Path part for operation deleteCompanyLocation
   */
  static readonly DeleteCompanyLocationPath = '/api/v1/locations/{companyLocationId}';

  /**
   * Delete company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLocation$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.DeleteCompanyLocationPath, 'delete');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLocation(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.deleteCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateCompanyLocation
   */
  static readonly UpdateCompanyLocationPath = '/api/v1/locations/{companyLocationId}';

  /**
   * update a  company locations.
   *
   * Update a part of the companyLocation. Only fields that are actually present in the request will be updated, the rest will stay the same. 
   *
   * Updates to mobilityStats, vehicleStats or presenceDays will delete the currently saved mobilityScore of the company.
   * Note, that when updating entrances, you can either not update any entrance by leaving out the entrance property, or update all entrances by adding them all. Furthermore, right now with this REST service entrances can't be added or deleted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyLocation$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CompanyLocationToChange
  }
): Observable<StrictHttpResponse<CompanyLocation>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.UpdateCompanyLocationPath, 'patch');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocation>;
      })
    );
  }

  /**
   * update a  company locations.
   *
   * Update a part of the companyLocation. Only fields that are actually present in the request will be updated, the rest will stay the same. 
   *
   * Updates to mobilityStats, vehicleStats or presenceDays will delete the currently saved mobilityScore of the company.
   * Note, that when updating entrances, you can either not update any entrance by leaving out the entrance property, or update all entrances by adding them all. Furthermore, right now with this REST service entrances can't be added or deleted.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCompanyLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyLocation(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CompanyLocationToChange
  }
): Observable<CompanyLocation> {

    return this.updateCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocation>) => r.body as CompanyLocation)
    );
  }

  /**
   * Path part for operation companyLocationPresenceDaysSuggestions
   */
  static readonly CompanyLocationPresenceDaysSuggestionsPath = '/api/v1/locations/{companyLocationId}/presenceDays/suggestions';

  /**
   * Get presenceDays suggestion.
   *
   * Get estimated presenceDays in the company based on previously uploaded detailed employee data.
   * Calculated by averaging the workTime of all uploaded employees and dividing by an assumed average work time of 8 hours per day given that all employees work their whole time at the companyLocation (e.g. no home office). <br />Depends on at least one employee in the company having the workTime attribute set.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationPresenceDaysSuggestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationPresenceDaysSuggestions$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationPresenceDaysSuggestionsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get presenceDays suggestion.
   *
   * Get estimated presenceDays in the company based on previously uploaded detailed employee data.
   * Calculated by averaging the workTime of all uploaded employees and dividing by an assumed average work time of 8 hours per day given that all employees work their whole time at the companyLocation (e.g. no home office). <br />Depends on at least one employee in the company having the workTime attribute set.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationPresenceDaysSuggestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationPresenceDaysSuggestions(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.companyLocationPresenceDaysSuggestions$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getOptimalCatchment
   */
  static readonly GetOptimalCatchmentPath = '/api/v1/locations/{companyLocationId}/optimalCatchment';

  /**
   * Calculate optimal catchment area.
   *
   * Optimal Catchment area for each individual transport mode and visualisation information for displaying carpooling information. <br /> The response consists for two parts: <br /> 1. a catchmentAreas field with a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each polygon contains a mode field with the travel mode, an employees field with the number of employees in the catchment area and a time field with the cutoff time of that isochrone in seconds. <br /> 2. a carpools field with the response object from the carpooling simulation (see the /simulation endpoint here)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOptimalCatchment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOptimalCatchment$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OptimalCatchmentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.GetOptimalCatchmentPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('entranceNumber', params.entranceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OptimalCatchmentResponse>;
      })
    );
  }

  /**
   * Calculate optimal catchment area.
   *
   * Optimal Catchment area for each individual transport mode and visualisation information for displaying carpooling information. <br /> The response consists for two parts: <br /> 1. a catchmentAreas field with a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each polygon contains a mode field with the travel mode, an employees field with the number of employees in the catchment area and a time field with the cutoff time of that isochrone in seconds. <br /> 2. a carpools field with the response object from the carpooling simulation (see the /simulation endpoint here)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOptimalCatchment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOptimalCatchment(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<OptimalCatchmentResponse> {

    return this.getOptimalCatchment$Response(params).pipe(
      map((r: StrictHttpResponse<OptimalCatchmentResponse>) => r.body as OptimalCatchmentResponse)
    );
  }

  /**
   * Path part for operation companyLocationModalSplitSuggestions
   */
  static readonly CompanyLocationModalSplitSuggestionsPath = '/api/v1/locations/{companyLocationId}/modalSplit/suggestions';

  /**
   * Get optimal modal split estimate for company.
   *
   * Get estimated optimal modal split based on employee locations that can be used as default values for packages or company modal split.<br />Keep in mind this function only works when employees are present and can take some time if employee travel distances and times need to be calculated first.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationModalSplitSuggestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationModalSplitSuggestions$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MobilityStats>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationModalSplitSuggestionsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MobilityStats>;
      })
    );
  }

  /**
   * Get optimal modal split estimate for company.
   *
   * Get estimated optimal modal split based on employee locations that can be used as default values for packages or company modal split.<br />Keep in mind this function only works when employees are present and can take some time if employee travel distances and times need to be calculated first.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationModalSplitSuggestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationModalSplitSuggestions(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<MobilityStats> {

    return this.companyLocationModalSplitSuggestions$Response(params).pipe(
      map((r: StrictHttpResponse<MobilityStats>) => r.body as MobilityStats)
    );
  }

  /**
   * Path part for operation companyLocationMobilityScoreRequest
   */
  static readonly CompanyLocationMobilityScoreRequestPath = '/api/v1/locations/{companyLocationId}/mobilityScore';

  /**
   * Get mobilityScore for current situation at company (accessible by premium users only).
   *
   * Read a quick explanation of how mobility scores are calculated in the backend. <br />https://docs.google.com/document/d/1YgnfK9NJOs4X8d_61AW_7FH-ATABclPpAQu3_x1kgpUThe additional option 'detailedTrip' allows to calculate trip distance and time for each employee individually to and from the company location with different entry and exit times e.g entry at 8am and exit at 17pm.
   * As opposed to clustering employees and assign the same trip distance and time for all employees in a cluster based on doubling the one-way results (2 x trip distance and time from employee location to company location at a specified time e.g 7am) of one employee in the cluster.
   * Trips contain a cluster id (each employee constitute their own cluster for individual calculations). If the mobility score is recalculated with the same employees without forcing distance recalculation, the clustering is done by regrouping the employees by the cluster id in their correspondent saved trips and the mobility score calculation is done using the data in the saved trips.
   * In case a different employee has been added, a new clustering occurs and all the trips are recalculated (previous trips in database are deleted). The case where this calculation will be flawed is when an employee's location is modified. Technically all the employees would have trips and the clustering is done by grouping the employees as aforementioned, however the trips of that specific employee are no longer correct.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationMobilityScoreRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationMobilityScoreRequest$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * async
     */
    async?: boolean;

    /**
     * force distance recalculation
     */
    forceDistanceRecalculation?: boolean;

    /**
     * force score recalculation
     */
    forceScoreRecalculation?: boolean;

    /**
     * calculate trip distance individually. &#x60;false&#x60; lead to clustering employees and using default entryTime.
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type, only works if &#x60;detailedTrips &#x3D; true&#x60;
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MobilityScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationMobilityScoreRequestPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('async', params.async, {});
      rb.query('forceDistanceRecalculation', params.forceDistanceRecalculation, {});
      rb.query('forceScoreRecalculation', params.forceScoreRecalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MobilityScoreResponse>;
      })
    );
  }

  /**
   * Get mobilityScore for current situation at company (accessible by premium users only).
   *
   * Read a quick explanation of how mobility scores are calculated in the backend. <br />https://docs.google.com/document/d/1YgnfK9NJOs4X8d_61AW_7FH-ATABclPpAQu3_x1kgpUThe additional option 'detailedTrip' allows to calculate trip distance and time for each employee individually to and from the company location with different entry and exit times e.g entry at 8am and exit at 17pm.
   * As opposed to clustering employees and assign the same trip distance and time for all employees in a cluster based on doubling the one-way results (2 x trip distance and time from employee location to company location at a specified time e.g 7am) of one employee in the cluster.
   * Trips contain a cluster id (each employee constitute their own cluster for individual calculations). If the mobility score is recalculated with the same employees without forcing distance recalculation, the clustering is done by regrouping the employees by the cluster id in their correspondent saved trips and the mobility score calculation is done using the data in the saved trips.
   * In case a different employee has been added, a new clustering occurs and all the trips are recalculated (previous trips in database are deleted). The case where this calculation will be flawed is when an employee's location is modified. Technically all the employees would have trips and the clustering is done by grouping the employees as aforementioned, however the trips of that specific employee are no longer correct.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationMobilityScoreRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationMobilityScoreRequest(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * async
     */
    async?: boolean;

    /**
     * force distance recalculation
     */
    forceDistanceRecalculation?: boolean;

    /**
     * force score recalculation
     */
    forceScoreRecalculation?: boolean;

    /**
     * calculate trip distance individually. &#x60;false&#x60; lead to clustering employees and using default entryTime.
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type, only works if &#x60;detailedTrips &#x3D; true&#x60;
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
  }
): Observable<MobilityScoreResponse> {

    return this.companyLocationMobilityScoreRequest$Response(params).pipe(
      map((r: StrictHttpResponse<MobilityScoreResponse>) => r.body as MobilityScoreResponse)
    );
  }

  /**
   * Path part for operation companyLocationMinimalMobilityScoreRequest
   */
  static readonly CompanyLocationMinimalMobilityScoreRequestPath = '/api/v1/locations/{companyLocationId}/minimalMobilityScore';

  /**
   * Get minimal version locationScore for current situation at company.
   *
   * Read a quick explanation of how mobility scores are calculated in the backend. <br />https://docs.google.com/document/d/1YgnfK9NJOs4X8d_61AW_7FH-ATABclPpAQu3_x1kgpUThe additional option 'detailedTrip' allows to calculate trip distance and time for each employee individually to and from the company location with different entry and exit times e.g entry at 8am and exit at 17pm.
   * As opposed to clustering employees and assign the same trip distance and time for all employees in a cluster based on doubling the one-way results (2 x trip distance and time from employee location to company location at a specified time e.g 7am) of one employee in the cluster.
   * To properly compare locationScore of different locations a logistic function is used to convert locationScores to more comparable values. The function looks as such.
   * https://www.desmos.com/calculator/8ijh9mciql
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationMinimalMobilityScoreRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationMinimalMobilityScoreRequest$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * async
     */
    async?: boolean;

    /**
     * force distance recalculation
     */
    forceDistanceRecalculation?: boolean;

    /**
     * force score recalculation
     */
    forceScoreRecalculation?: boolean;

    /**
     * calculate trip distance individually. &#x60;false&#x60; lead to clustering employees and using default entryTime.
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type, only works if &#x60;detailedTrips &#x3D; true&#x60;
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MinimalMobilityScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationMinimalMobilityScoreRequestPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('async', params.async, {});
      rb.query('forceDistanceRecalculation', params.forceDistanceRecalculation, {});
      rb.query('forceScoreRecalculation', params.forceScoreRecalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MinimalMobilityScoreResponse>;
      })
    );
  }

  /**
   * Get minimal version locationScore for current situation at company.
   *
   * Read a quick explanation of how mobility scores are calculated in the backend. <br />https://docs.google.com/document/d/1YgnfK9NJOs4X8d_61AW_7FH-ATABclPpAQu3_x1kgpUThe additional option 'detailedTrip' allows to calculate trip distance and time for each employee individually to and from the company location with different entry and exit times e.g entry at 8am and exit at 17pm.
   * As opposed to clustering employees and assign the same trip distance and time for all employees in a cluster based on doubling the one-way results (2 x trip distance and time from employee location to company location at a specified time e.g 7am) of one employee in the cluster.
   * To properly compare locationScore of different locations a logistic function is used to convert locationScores to more comparable values. The function looks as such.
   * https://www.desmos.com/calculator/8ijh9mciql
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationMinimalMobilityScoreRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationMinimalMobilityScoreRequest(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * async
     */
    async?: boolean;

    /**
     * force distance recalculation
     */
    forceDistanceRecalculation?: boolean;

    /**
     * force score recalculation
     */
    forceScoreRecalculation?: boolean;

    /**
     * calculate trip distance individually. &#x60;false&#x60; lead to clustering employees and using default entryTime.
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type, only works if &#x60;detailedTrips &#x3D; true&#x60;
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
  }
): Observable<MinimalMobilityScoreResponse> {

    return this.companyLocationMinimalMobilityScoreRequest$Response(params).pipe(
      map((r: StrictHttpResponse<MinimalMobilityScoreResponse>) => r.body as MinimalMobilityScoreResponse)
    );
  }

  /**
   * Path part for operation companyLocationDistances
   */
  static readonly CompanyLocationDistancesPath = '/api/v1/locations/{companyLocationId}/distances';

  /**
   * Get distances in a company location.
   *
   * Legacy API from old transportLegs, currently not working with the changes sto the way trips are stored.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationDistances()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  companyLocationDistances$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<TripDurations>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationDistancesPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TripDurations>>;
      })
    );
  }

  /**
   * Get distances in a company location.
   *
   * Legacy API from old transportLegs, currently not working with the changes sto the way trips are stored.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationDistances$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  companyLocationDistances(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<TripDurations>> {

    return this.companyLocationDistances$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TripDurations>>) => r.body as Array<TripDurations>)
    );
  }

  /**
   * Path part for operation companyLocationCatchmentRequest
   */
  static readonly CompanyLocationCatchmentRequestPath = '/api/v1/locations/{companyLocationId}/catchment';

  /**
   * Calculate catchment area for mode.
   *
   * In V1 the catchment area for a specific mode is calculated whenever this REST service is called. One request can only calculate the catchment area for one transport mode and a given set of time steps. <br />The response is a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each <br />polygon should contain a time field with the travel time of that <br /> catchment area and the number of employees within that polygon. <br /> Also, we can add information about how many employees (or population of a certain age, education, etc) are within the given catchment area to the properties-object. <br /> An example response can be found here. <br /> In future versions, calculating catchment areas for multiple catchment areas could be supported by changing the mode parameter into an array.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationCatchmentRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationCatchmentRequest$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode of transport for calculating the catchment area
     */
    mode: 'walk' | 'bike' | 'transit' | 'car';

    /**
     * travel time steps for calculating catchment area in minutes
     */
    times: Array<number>;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CatchmentGeoJson>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationCatchmentRequestPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('mode', params.mode, {});
      rb.query('times', params.times, {});
      rb.query('entranceNumber', params.entranceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CatchmentGeoJson>;
      })
    );
  }

  /**
   * Calculate catchment area for mode.
   *
   * In V1 the catchment area for a specific mode is calculated whenever this REST service is called. One request can only calculate the catchment area for one transport mode and a given set of time steps. <br />The response is a geojson FeatureCollection that consists of one Polygon or MultiPolygon per time step. The properties-object of each <br />polygon should contain a time field with the travel time of that <br /> catchment area and the number of employees within that polygon. <br /> Also, we can add information about how many employees (or population of a certain age, education, etc) are within the given catchment area to the properties-object. <br /> An example response can be found here. <br /> In future versions, calculating catchment areas for multiple catchment areas could be supported by changing the mode parameter into an array.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationCatchmentRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationCatchmentRequest(params: {

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * mode of transport for calculating the catchment area
     */
    mode: 'walk' | 'bike' | 'transit' | 'car';

    /**
     * travel time steps for calculating catchment area in minutes
     */
    times: Array<number>;

    /**
     * the number of the entrance used as a center for the catchment calculation
     */
    entranceNumber?: number;
    context?: HttpContext
  }
): Observable<CatchmentGeoJson> {

    return this.companyLocationCatchmentRequest$Response(params).pipe(
      map((r: StrictHttpResponse<CatchmentGeoJson>) => r.body as CatchmentGeoJson)
    );
  }

  /**
   * Path part for operation allCompanyLocationsPaging
   */
  static readonly AllCompanyLocationsPagingPath = '/api/v1/locations/paging';

  /**
   * List all company locations in pages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allCompanyLocationsPaging()` instead.
   *
   * This method doesn't expect any request body.
   */
  allCompanyLocationsPaging$Response(params?: {

    /**
     * page number
     */
    page?: number;

    /**
     * page size
     */
    size?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CompanyLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.AllCompanyLocationsPagingPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyLocation>>;
      })
    );
  }

  /**
   * List all company locations in pages.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allCompanyLocationsPaging$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allCompanyLocationsPaging(params?: {

    /**
     * page number
     */
    page?: number;

    /**
     * page size
     */
    size?: number;
    context?: HttpContext
  }
): Observable<Array<CompanyLocation>> {

    return this.allCompanyLocationsPaging$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyLocation>>) => r.body as Array<CompanyLocation>)
    );
  }

}
