import { Injectable } from '@angular/core';
import { captureException, captureMessage, showReportDialog } from '@sentry/angular-ivy';
import { UserDataService } from '@shared/services/user-data.service';
import { FeedbackDialogTranslations } from '@shared/translations/feedback-dialog-translations';

@Injectable({
    providedIn: 'root',
})
export class SentryReportService {
    public readonly translations: typeof FeedbackDialogTranslations = FeedbackDialogTranslations;

    constructor(private readonly userDataService: UserDataService) {}

    /**
     * This function reports normal Errors or objects to Sentry
     * @param error
     * @param extra
     */
    public reportError(
        error: string | { [prop: string]: unknown } | Error,
        extra: {} = null,
    ): string {
        let exception: unknown = error;

        if (typeof error === 'string' || error instanceof String) {
            return captureException(new Error(error as string));
        } else if ('code' in error) {
            exception = new Error(error.code as string);
            extra = error;
        } else if (!(error instanceof Error)) {
            exception = new Error(JSON.stringify(error));
            extra = error;
        }

        return captureException(exception, {
            extra: extra,
        });
    }

    public reportUserError(eventId: string = null): void {
        if (eventId == null) {
            eventId = captureMessage(
                'User Feedback Submitted - ' + this.userDataService.user?.id.toString(),
            );
        }
        showReportDialog({
            eventId: eventId,
            title: this.translations.feedbackDialogTitle,
            subtitle: '',
            subtitle2: this.translations.feedbackDialogSubtitle2,
            labelComments: this.translations.feedbackCommentLabel,
            labelSubmit: this.translations.feedbackSubmitBtnLabel,
        });
    }
}
