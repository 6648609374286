/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class EmployeeDebugControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployeesAsCsv
   */
  static readonly GetEmployeesAsCsvPath = '/debug/cl/{companyLocationId}/employees';

  /**
   * get employees as csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeesAsCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesAsCsv$Response(params: {
    companyLocationId: number;
    mode?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeDebugControllerImplService.GetEmployeesAsCsvPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('mode', params.mode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * get employees as csv.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeesAsCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesAsCsv(params: {
    companyLocationId: number;
    mode?: string;
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getEmployeesAsCsv$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getEmployeesAsCsv1
   */
  static readonly GetEmployeesAsCsv1Path = '/d/cl/{companyLocationId}/employees';

  /**
   * get employees as csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeesAsCsv1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesAsCsv1$Response(params: {
    companyLocationId: number;
    mode?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeDebugControllerImplService.GetEmployeesAsCsv1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('mode', params.mode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * get employees as csv.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeesAsCsv1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesAsCsv1(params: {
    companyLocationId: number;
    mode?: string;
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getEmployeesAsCsv1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
