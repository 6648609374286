import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpErrors } from '@shared/constants/http-errors.constant';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import { HttpError } from '@shared/interfaces/http-error.interface';
import { ToastService } from '@shared/services/toast.service';
import { UserDataService } from '@shared/services/user-data.service';
import { ResponseErrorTranslations } from '@shared/translations/response-error-translations';
import { firstValueFrom } from 'rxjs';
import ErrorEvent = google.maps.ErrorEvent;

@Injectable({
    providedIn: 'root',
})
export class HttpErrorToastService {
    constructor(
        private readonly toastService: ToastService,
        private readonly userDataService: UserDataService,
    ) {
        // Nothing to do here
    }

    public async toastError(
        error: HttpErrorResponse,
        forceOpen = false,
        sentryEventId: string = null,
    ): Promise<void> {
        let displayRawErrors = this.userDataService.user
            ? await firstValueFrom(
                  this.userDataService.showFeature$(FeatureFlag.DISPLAY_RAW_ERRORS),
              )
            : false;

        if (error.error instanceof ErrorEvent) {
            this.toastService.showInfoEvent(
                ResponseErrorTranslations.error,
                true,
                true,
                ResponseErrorTranslations.failedAction,
                null,
                null,
                displayRawErrors ? error : null,
                true,
                sentryEventId,
            );
            // client-side error
            // retry request
            // rxjs retry function
        } else {
            // server side error
            const statusCode: number = (error as HttpErrorResponse).status;
            const rawMessage: {} = error.error;
            const errorCode: string = error.error.code;
            const additionalProperties: {} = error.error.additionalProperties ?? {};
            const httpErrorDescription: HttpError = httpErrors[errorCode];

            if (!httpErrorDescription) {
                // If errorCode is null it means we have an exception without error code so for example a 401 unauthorized.
                this.toastService.showInfoEvent(
                    ResponseErrorTranslations.error,
                    true,
                    true,
                    ResponseErrorTranslations.failedRequest,
                    null,
                    null,
                    displayRawErrors ? rawMessage : null,
                    true,
                    sentryEventId,
                );
                if (errorCode != null) {
                    // disabled because this needs warnings
                    // eslint-disable-next-line no-console
                    console.warn(statusCode + ' status: no handler for error code: ' + errorCode);
                }

                return;
            }

            if (httpErrorDescription.ignore) {
                return;
            }

            const enableReport: boolean = httpErrorDescription.report ?? true;
            displayRawErrors = httpErrorDescription.showRawMessage ?? displayRawErrors;

            // check for undefined
            let message: string = null;
            if (httpErrorDescription.body) {
                if (
                    typeof httpErrorDescription.body === 'string' ||
                    httpErrorDescription.body instanceof String
                ) {
                    message = httpErrorDescription.body as string;
                } else {
                    message = httpErrorDescription.body(additionalProperties);
                }
            }
            let title: string | null = httpErrorDescription.title ?? null; // undefined to null
            const sticky: boolean = forceOpen || (httpErrorDescription.sticky ?? false);
            const link: string = httpErrorDescription.link ?? null;

            const checkForNull: string[] = [message, title];
            if (checkForNull.includes(null)) {
                title = checkForNull.filter(value => value != null)[0];
                message = null;
            }

            this.toastService.showInfoEvent(
                title,
                true,
                sticky,
                message,
                link,
                null,
                displayRawErrors ? rawMessage : null,
                enableReport,
                sentryEventId,
            );
        }
    }
}
