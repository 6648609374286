/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FreeBikeImprovementSuggestion } from '../models/free-bike-improvement-suggestion';
import { HomeOfficeImprovementSuggestion } from '../models/home-office-improvement-suggestion';
import { ImprovementSuggestion } from '../models/improvement-suggestion';
import { InterventionImpact } from '../models/intervention-impact';
import { JobTicketImprovementSuggestion } from '../models/job-ticket-improvement-suggestion';
import { Package } from '../models/package';
import { PackageResponse } from '../models/package-response';

@Injectable({
  providedIn: 'root',
})
export class ImprovementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getImprovementImpact
   */
  static readonly GetImprovementImpactPath = '/api/v1/locations/{companyLocationId}/improvements/getImprovementImpact';

  /**
   * Get improvement impact.
   *
   * calculate the impact of a posted improvement suggestion
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImprovementImpact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getImprovementImpact$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: (FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)
  }
): Observable<StrictHttpResponse<InterventionImpact>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.GetImprovementImpactPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InterventionImpact>;
      })
    );
  }

  /**
   * Get improvement impact.
   *
   * calculate the impact of a posted improvement suggestion
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImprovementImpact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getImprovementImpact(params: {
    companyLocationId: number;
    context?: HttpContext
    body: (FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)
  }
): Observable<InterventionImpact> {

    return this.getImprovementImpact$Response(params).pipe(
      map((r: StrictHttpResponse<InterventionImpact>) => r.body as InterventionImpact)
    );
  }

  /**
   * Path part for operation createPackage
   */
  static readonly CreatePackagePath = '/api/v1/locations/{companyLocationId}/improvements/createPackage';

  /**
   * Get improvement package.
   *
   * Calculate a package. Improvements can be added through the interventions attribute in the package.The option for an improvement only package where only the best out of the current and the estimated mode for employees is used, can be enabled through the improvementOnly flag in the package entity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPackage$Response(params: {
    companyLocationId: number;
    forceDistanceRecalculation?: boolean;
    detailedTrip?: boolean;
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: Package
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.CreatePackagePath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('forceDistanceRecalculation', params.forceDistanceRecalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * Get improvement package.
   *
   * Calculate a package. Improvements can be added through the interventions attribute in the package.The option for an improvement only package where only the best out of the current and the estimated mode for employees is used, can be enabled through the improvementOnly flag in the package entity.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPackage(params: {
    companyLocationId: number;
    forceDistanceRecalculation?: boolean;
    detailedTrip?: boolean;
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: Package
  }
): Observable<PackageResponse> {

    return this.createPackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation suggestedImprovements
   */
  static readonly SuggestedImprovementsPath = '/api/v1/locations/{companyLocationId}/improvements/suggestedImprovements';

  /**
   * Get suggested improvement templates.
   *
   * fetch improvement suggestions as pre-defined measures
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestedImprovements()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestedImprovements$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<ImprovementSuggestion>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.SuggestedImprovementsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImprovementSuggestion>>;
      })
    );
  }

  /**
   * Get suggested improvement templates.
   *
   * fetch improvement suggestions as pre-defined measures
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestedImprovements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestedImprovements(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<ImprovementSuggestion>> {

    return this.suggestedImprovements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ImprovementSuggestion>>) => r.body as Array<ImprovementSuggestion>)
    );
  }

}
