/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PublicTransportPricingZone } from '../models/public-transport-pricing-zone';

@Injectable({
  providedIn: 'root',
})
export class PublicTransportCostService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPublicTransportPricingZones
   */
  static readonly GetPublicTransportPricingZonesPath = '/d/publicTransportPricingZones';

  /**
   * get public transport pricing zones saved in the database.
   *
   * get public transport pricing zones saved in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicTransportPricingZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZones$Response(params?: {

    /**
     * name of public transport ticket
     */
    ticketName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicTransportPricingZone>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportCostService.GetPublicTransportPricingZonesPath, 'get');
    if (params) {
      rb.query('ticketName', params.ticketName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicTransportPricingZone>>;
      })
    );
  }

  /**
   * get public transport pricing zones saved in the database.
   *
   * get public transport pricing zones saved in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicTransportPricingZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZones(params?: {

    /**
     * name of public transport ticket
     */
    ticketName?: string;
    context?: HttpContext
  }
): Observable<Array<PublicTransportPricingZone>> {

    return this.getPublicTransportPricingZones$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicTransportPricingZone>>) => r.body as Array<PublicTransportPricingZone>)
    );
  }

  /**
   * Path part for operation getPublicTransportPricingZones1
   */
  static readonly GetPublicTransportPricingZones1Path = '/debug/publicTransportPricingZones';

  /**
   * get public transport pricing zones saved in the database.
   *
   * get public transport pricing zones saved in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicTransportPricingZones1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZones1$Response(params?: {

    /**
     * name of public transport ticket
     */
    ticketName?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicTransportPricingZone>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportCostService.GetPublicTransportPricingZones1Path, 'get');
    if (params) {
      rb.query('ticketName', params.ticketName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicTransportPricingZone>>;
      })
    );
  }

  /**
   * get public transport pricing zones saved in the database.
   *
   * get public transport pricing zones saved in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicTransportPricingZones1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZones1(params?: {

    /**
     * name of public transport ticket
     */
    ticketName?: string;
    context?: HttpContext
  }
): Observable<Array<PublicTransportPricingZone>> {

    return this.getPublicTransportPricingZones1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicTransportPricingZone>>) => r.body as Array<PublicTransportPricingZone>)
    );
  }

  /**
   * Path part for operation defaultPublicTransportPricingZones
   */
  static readonly DefaultPublicTransportPricingZonesPath = '/d/defaultPublicTransportPricingZones';

  /**
   * Save default public transport pricing zones in the database.
   *
   * Save default public transport pricing zones in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPublicTransportPricingZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultPublicTransportPricingZones$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicTransportPricingZone>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportCostService.DefaultPublicTransportPricingZonesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicTransportPricingZone>>;
      })
    );
  }

  /**
   * Save default public transport pricing zones in the database.
   *
   * Save default public transport pricing zones in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `defaultPublicTransportPricingZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultPublicTransportPricingZones(params?: {
    context?: HttpContext
  }
): Observable<Array<PublicTransportPricingZone>> {

    return this.defaultPublicTransportPricingZones$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicTransportPricingZone>>) => r.body as Array<PublicTransportPricingZone>)
    );
  }

  /**
   * Path part for operation defaultPublicTransportPricingZones1
   */
  static readonly DefaultPublicTransportPricingZones1Path = '/debug/defaultPublicTransportPricingZones';

  /**
   * Save default public transport pricing zones in the database.
   *
   * Save default public transport pricing zones in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `defaultPublicTransportPricingZones1()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultPublicTransportPricingZones1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicTransportPricingZone>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportCostService.DefaultPublicTransportPricingZones1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicTransportPricingZone>>;
      })
    );
  }

  /**
   * Save default public transport pricing zones in the database.
   *
   * Save default public transport pricing zones in the database. This service is only accessible by the admin and require basic auth to use.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `defaultPublicTransportPricingZones1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  defaultPublicTransportPricingZones1(params?: {
    context?: HttpContext
  }
): Observable<Array<PublicTransportPricingZone>> {

    return this.defaultPublicTransportPricingZones1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicTransportPricingZone>>) => r.body as Array<PublicTransportPricingZone>)
    );
  }

}
