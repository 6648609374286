<div *ngIf="title" [attr.aria-label]="title" [class]="options.titleClass">
    {{ title }}
    <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
</div>
<div
    *ngIf="message && options.enableHtml"
    [class]="options.messageClass"
    [innerHTML]="message"
    aria-live="polite"
    role="alertdialog"
    style="display: inline-block !important"></div>
<div
    *ngIf="message && !options.enableHtml"
    [attr.aria-label]="message"
    [class]="options.messageClass"
    aria-live="polite"
    role="alertdialog"
    style="display: inline-block !important">
    {{ message }}
</div>

<div
    *ngIf="rawMessage"
    [attr.aria-label]="message"
    [class]="options.messageClass"
    aria-live="polite"
    id="messagebody"
    role="alertdialog">
    <p *ngIf="!expandRawMessage" i18n="@@fullErrorFeedback" style="flex: 1">
        Check The Full Error Message
    </p>
    <pre
        *ngIf="rawMessageIsJson && expandRawMessage"
        [ngStyle]="
            expandRawMessage
                ? { 'white-space': 'pre-wrap' }
                : { 'white-space': 'nowrap', overflow: 'hidden', flex: 1 }
        "
        >{{ rawMessage | json }}</pre
    >
    <p
        *ngIf="!rawMessageIsJson && expandRawMessage"
        [ngStyle]="
            expandRawMessage ? {} : { 'white-space': 'nowrap', overflow: 'hidden', flex: 1 }
        ">
        {{ message }}
    </p>
    <div
        *ngIf="!expandRawMessage"
        style="display: inline-block; background-color: rgba(138, 47, 38, 1)">
        <mat-icon>expand_more</mat-icon>
    </div>
</div>

<div *ngIf="informationLink || options.closeButton || showDashboardButton" class="footer">
    <span *ngIf="!informationLink"></span>
    <span *ngIf="informationLink" i18n="@@errorInformation">
        More Information: <a href="{{ informationLink }}">HELP</a>
    </span>

    <div class="buttons">
        <button
            (click)="reportError()"
            *ngIf="enableReport"
            class="report-button"
            color="warn"
            mat-flat-button>
            <span i18n="@@report">Report</span>
        </button>

        <button
            (click)="onGoToDashboardClick()"
            *ngIf="showDashboardButton"
            class="report-button"
            color="primary"
            mat-flat-button>
            <span i18n="@@returnToDashboard">Go To Dashboard</span>
        </button>

        <button
            (click)="remove()"
            *ngIf="options.closeButton"
            class="close-button"
            color="warn"
            mat-flat-button>
            <span i18n="@@close">Close</span>
        </button>
    </div>
</div>
<div *ngIf="options.progressBar">
    <div [style.width]="width + '%'" class="toast-progress"></div>
</div>
