/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GtfsAgency } from '../models/gtfs-agency';

@Injectable({
  providedIn: 'root',
})
export class TestNewMobilityScoreService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tripsToDetailed
   */
  static readonly TripsToDetailedPath = '/d/tripsToDetailed/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tripsToDetailed()` instead.
   *
   * This method doesn't expect any request body.
   */
  tripsToDetailed$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TestNewMobilityScoreService.TripsToDetailedPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tripsToDetailed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tripsToDetailed(params: {
    id: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.tripsToDetailed$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOptimalScore
   */
  static readonly GetOptimalScorePath = '/d/score/{id}/optimal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOptimalScore()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOptimalScore$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TestNewMobilityScoreService.GetOptimalScorePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOptimalScore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOptimalScore(params: {
    id: number;
    context?: HttpContext
  }
): Observable<{
[key: string]: {
};
}> {

    return this.getOptimalScore$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getScore
   */
  static readonly GetScorePath = '/d/score/{id}/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScore()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScore$Response(params: {
    id: number;
    save?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, TestNewMobilityScoreService.GetScorePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('save', params.save, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScore(params: {
    id: number;
    save?: boolean;
    context?: HttpContext
  }
): Observable<{
[key: string]: {
};
}> {

    return this.getScore$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>) => r.body as {
[key: string]: {
};
})
    );
  }

  /**
   * Path part for operation getRoute
   */
  static readonly GetRoutePath = '/d/routers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoute()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoute$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, TestNewMobilityScoreService.GetRoutePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRoute$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoute(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.getRoute$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAgencies
   */
  static readonly GetAgenciesPath = '/d/agencies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgencies$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GtfsAgency>>> {

    const rb = new RequestBuilder(this.rootUrl, TestNewMobilityScoreService.GetAgenciesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GtfsAgency>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgencies(params?: {
    context?: HttpContext
  }
): Observable<Array<GtfsAgency>> {

    return this.getAgencies$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GtfsAgency>>) => r.body as Array<GtfsAgency>)
    );
  }

}
