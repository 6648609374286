import { Injectable } from '@angular/core';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserInfo } from '../../api/models/user-info';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    public _user$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);

    public constructor() {
        // nothing to do here
    }

    public get user$(): Observable<UserInfo> {
        return this._user$.pipe(filter(value => value != null));
    }

    public get user(): UserInfo {
        return this._user$.value;
    }

    /**
     * Update the user
     * @param userInfo
     */
    public updateUserInfo(userInfo: UserInfo): void {
        this._user$.next(userInfo);
    }

    /**
     * Resets this service
     */
    public reset(): void {
        this._user$.next(null);
    }

    public isPremiumUser(): boolean {
        return this._user$.value != null ? this._user$.value.accountType === 'PREMIUM' : false;
    }

    /**
     * Helper function to check if user subscription is premium
     */
    public isPremiumUser$(): Observable<boolean> {
        return this.user$.pipe(
            map(user => {
                return user.accountType === 'PREMIUM';
            }),
        );
    }

    /**
     * Helper function to check if user is allowed to see a particular feature
     */
    public showFeature(feature: FeatureFlag): boolean {
        // TODO migrate code to use this function when okay
        return this._user$.value != null ? this._user$.value.features[feature] === true : false;
    }

    /**
     * Helper function to check if user is allowed to see a particular feature
     */
    public showFeature$(feature: FeatureFlag): Observable<boolean> {
        return this.user$.pipe(
            map(user => {
                return user.features[feature] === true;
            }),
        );
    }

    /**
     * Helper function to check if user subscription is premium
     */
    public isLightUser$(): Observable<boolean> {
        return this.user$.pipe(
            map(user => {
                return user.accountType === 'LIGHT';
            }),
        );
    }

    public isViewOnlyUser(): boolean {
        return this._user$.value != null ? this._user$.value.accountType === 'VIEW_ONLY' : false;
    }

    /**
     * Helper function to check if user subscription is view only
     */
    public isViewOnlyUser$(): Observable<boolean> {
        return this.user$.pipe(
            map(user => {
                return user.accountType === 'VIEW_ONLY';
            }),
        );
    }

    /**
     * Helper function to check if user has maxed out his analysis
     */
    public isAllowedToAnalyse$(): Observable<boolean> {
        return this.user$.pipe(
            map(user => {
                if (!user) {
                    return false;
                }

                return (
                    user.maxCalculations == null || user.currentCalculations < user.maxCalculations
                );
            }),
        );
    }
}
