import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor() {
        // nothing left to do
    }

    /**
     * get hex code from a css variable name
     * @param cssVarName
     */
    public getHexFromCSSVariable(cssVarName: string): string {
        return getComputedStyle(document.body).getPropertyValue(cssVarName);
    }

    /* Get color based on trip mode */
    public getModeColor(mode: string): string {
        switch (mode) {
            case 'WALK':
                return this.getHexFromCSSVariable('--walk-color');
            case 'BIKE':
                return this.getHexFromCSSVariable('--bike-color');
            case 'WAIT':
                return '#fff';
            case 'CAR_DRIVER_TRAFFIC':
                return this.getHexFromCSSVariable('--danger-color');
            case 'CAR_DRIVER':
                return this.getHexFromCSSVariable('--car-driver-color');
            case 'CAR_PASSENGER':
                return this.getHexFromCSSVariable('--car-passenger-color');
            case 'PUBLIC_TRANSPORT':
                return this.getHexFromCSSVariable('--public-transport-color');
            default:
                return this.getHexFromCSSVariable('--public-transport-color');
        }
    }
}
