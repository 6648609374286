/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PostedCompanyLocation } from '../models/posted-company-location';

@Injectable({
  providedIn: 'root',
})
export class CompanyLocationImportExportControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation importCompanyLocation
   */
  static readonly ImportCompanyLocationPath = '/debug/companyLocation/import';

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCompanyLocation()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation$Response(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<StrictHttpResponse<Array<PostedCompanyLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ImportCompanyLocationPath, 'post');
    if (params) {
      rb.query('userName', params.userName, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostedCompanyLocation>>;
      })
    );
  }

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCompanyLocation$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<Array<PostedCompanyLocation>> {

    return this.importCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PostedCompanyLocation>>) => r.body as Array<PostedCompanyLocation>)
    );
  }

  /**
   * Path part for operation importCompanyLocation1
   */
  static readonly ImportCompanyLocation1Path = '/debug/cl/import';

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCompanyLocation1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation1$Response(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<StrictHttpResponse<Array<PostedCompanyLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ImportCompanyLocation1Path, 'post');
    if (params) {
      rb.query('userName', params.userName, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostedCompanyLocation>>;
      })
    );
  }

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCompanyLocation1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation1(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<Array<PostedCompanyLocation>> {

    return this.importCompanyLocation1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PostedCompanyLocation>>) => r.body as Array<PostedCompanyLocation>)
    );
  }

  /**
   * Path part for operation importCompanyLocation2
   */
  static readonly ImportCompanyLocation2Path = '/d/companyLocation/import';

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCompanyLocation2()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation2$Response(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<StrictHttpResponse<Array<PostedCompanyLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ImportCompanyLocation2Path, 'post');
    if (params) {
      rb.query('userName', params.userName, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostedCompanyLocation>>;
      })
    );
  }

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCompanyLocation2$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation2(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<Array<PostedCompanyLocation>> {

    return this.importCompanyLocation2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PostedCompanyLocation>>) => r.body as Array<PostedCompanyLocation>)
    );
  }

  /**
   * Path part for operation importCompanyLocation3
   */
  static readonly ImportCompanyLocation3Path = '/d/cl/import';

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importCompanyLocation3()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation3$Response(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<StrictHttpResponse<Array<PostedCompanyLocation>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ImportCompanyLocation3Path, 'post');
    if (params) {
      rb.query('userName', params.userName, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostedCompanyLocation>>;
      })
    );
  }

  /**
   * import company location from a multipart file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importCompanyLocation3$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importCompanyLocation3(params?: {
    userName?: string;
    context?: HttpContext
    body?: Blob
  }
): Observable<Array<PostedCompanyLocation>> {

    return this.importCompanyLocation3$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PostedCompanyLocation>>) => r.body as Array<PostedCompanyLocation>)
    );
  }

  /**
   * Path part for operation exportCompanyLocation
   */
  static readonly ExportCompanyLocationPath = '/d/companyLocation/export/{companyLocationId}';

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCompanyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ExportCompanyLocationPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCompanyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exportCompanyLocation1
   */
  static readonly ExportCompanyLocation1Path = '/debug/companyLocation/export/{companyLocationId}';

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCompanyLocation1()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation1$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ExportCompanyLocation1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCompanyLocation1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation1(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportCompanyLocation1$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exportCompanyLocation2
   */
  static readonly ExportCompanyLocation2Path = '/debug/cl/export/{companyLocationId}';

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCompanyLocation2()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation2$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ExportCompanyLocation2Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCompanyLocation2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation2(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportCompanyLocation2$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation exportCompanyLocation3
   */
  static readonly ExportCompanyLocation3Path = '/d/cl/export/{companyLocationId}';

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCompanyLocation3()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation3$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationImportExportControllerImplService.ExportCompanyLocation3Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * export company location from as zip file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `exportCompanyLocation3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCompanyLocation3(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<any> {

    return this.exportCompanyLocation3$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

}
