import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorNotAObjectError } from '@shared/classes/error-not-a-object.error';
import { httpErrors } from '@shared/constants/http-errors.constant';
import { sentryErrorCodes } from '@shared/constants/sentry-error-codes.constant';
import { AuthService } from '@shared/services/auth.service';
import { HttpErrorToastService } from '@shared/services/http-error-toast.service';
import { SentryReportService } from '@shared/services/sentry-report.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly httpErrorToastService: HttpErrorToastService,
        private readonly authService: AuthService,
        private readonly toastService: ToastService,
        private readonly sentryReportService: SentryReportService,
    ) {
        // nothing to do here
    }

    /**
     * Intercepts HttpRequests and handles 401 unauthorized errors
     * @param request
     * @param next
     */
    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const hasUserAuth: boolean = this.authService.getJwtToken() != null;
        const isApiUrl: boolean = request.url.startsWith(environment.apiUrl);

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (typeof error.error === 'string' || error.error instanceof String) {
                    try {
                        // eslint-disable-next-line no-console
                        const newError = new HttpErrorResponse({
                            error: JSON.parse(error.error as string),
                            headers: error.headers,
                            status: error.status,
                            statusText: error.statusText,
                            url: error.url,
                        });

                        this.sentryReportService.reportError(
                            new ErrorNotAObjectError(newError.error?.code),
                            newError.error,
                        );

                        return throwError(() => newError);
                    } catch (e) {
                        // cant convert it :( lets return the old error
                    }
                }

                return throwError(() => error);
            }),
            retry({
                // omit count for unlimited retries
                delay: (error: HttpErrorResponse, retryCount) => {
                    if (error?.error?.code === 'ON_GOING_ANALYSIS_ERROR') {
                        return timer(10000);
                    } else {
                        // This skips the retry (isn't a weird hack, that's defined behaviour written in the docs...)
                        return throwError(() => error);
                    }
                },
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && hasUserAuth && isApiUrl) {
                    this.authService.logout();
                    this.toastService.showInfoEvent(
                        $localize`:@@loggedOut:Your session expired - login again`,
                        true,
                    );
                } else if (
                    error?.error &&
                    error?.error?.code !== 'NO_LOCATION_PROPERTIES' &&
                    error?.error?.code !== 'LOCATION_WITH_ALREADY_ASSIGNED_MOBILITY_PROPERTIES' &&
                    (!error?.error?.missing || error?.error?.missing.length === 0)
                ) {
                    let sentryEventId: string = null;

                    /* An error will be logged to Sentry if any of the following conditions are met:
                     * 1. The API error code is null/not existent.
                     * 2. The API error code is present in the sentryErrorCodes files
                     *    or not present in the httpErrors file (So basically if we have an error code, but dont know what it means)
                     */
                    if (
                        error?.error?.code == null ||
                        sentryErrorCodes.includes(error.error.code) ||
                        !Object.keys(httpErrors).includes(error.error.code)
                    ) {
                        sentryEventId = this.sentryReportService.reportError(error.error);
                    }

                    void this.httpErrorToastService.toastError(error, false, sentryEventId);
                }

                return throwError(() => error);
            }),
        );
    }
}
