/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimezoneApiResponse } from '../models/timezone-api-response';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTimezone
   */
  static readonly GetTimezonePath = '/api/v1/timezone';

  /**
   * Get the timezone of a location at a given time.
   *
   * This request calls the google maps timezone API, and returns the result without further changes. For more information on how the google maps timezone API behaves see the external documentation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimezone()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimezone$Response(params: {

    /**
     * A comma-separated latitude,longitude tuple, &#x60;location&#x3D;39.6034810,-119.6822510&#x60;, representing the location to look up.
     */
    location: string;

    /**
     * The desired time as seconds since midnight, January 1, 1970 UTC. The Time Zone API uses the &#x60;timestamp&#x60; to determine whether or not Daylight Savings should be applied, based on the time zone of the &#x60;location&#x60;.
     *
     * Note that the API does not take historical time zones into account. That is, if you specify a past timestamp, the API does not take into account the possibility that the location was previously in a different time zone.
     */
    timestamp: number;

    /**
     * The language in which to return results.
     */
    language?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TimezoneApiResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TimezoneService.GetTimezonePath, 'get');
    if (params) {
      rb.query('location', params.location, {});
      rb.query('timestamp', params.timestamp, {});
      rb.query('language', params.language, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TimezoneApiResponse>;
      })
    );
  }

  /**
   * Get the timezone of a location at a given time.
   *
   * This request calls the google maps timezone API, and returns the result without further changes. For more information on how the google maps timezone API behaves see the external documentation.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimezone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimezone(params: {

    /**
     * A comma-separated latitude,longitude tuple, &#x60;location&#x3D;39.6034810,-119.6822510&#x60;, representing the location to look up.
     */
    location: string;

    /**
     * The desired time as seconds since midnight, January 1, 1970 UTC. The Time Zone API uses the &#x60;timestamp&#x60; to determine whether or not Daylight Savings should be applied, based on the time zone of the &#x60;location&#x60;.
     *
     * Note that the API does not take historical time zones into account. That is, if you specify a past timestamp, the API does not take into account the possibility that the location was previously in a different time zone.
     */
    timestamp: number;

    /**
     * The language in which to return results.
     */
    language?: string;
    context?: HttpContext
  }
): Observable<TimezoneApiResponse> {

    return this.getTimezone$Response(params).pipe(
      map((r: StrictHttpResponse<TimezoneApiResponse>) => r.body as TimezoneApiResponse)
    );
  }

}
