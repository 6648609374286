import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { languages } from '@shared/constants/languages';
import { LanguageKeys } from '@shared/enums/languages.enum';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class LanguageSwitcherService {
    constructor(
        private cookieService: CookieService,
        private router: Router,
        @Inject(LOCALE_ID) private locale: string,
    ) {}

    /**
     * reloads webpage on another language path
     */
    public getCurrentLanguage(): LanguageKeys {
        return LanguageKeys[this.locale.toUpperCase()];
    }

    /**
     * reloads webpage on another language path
     * @param language
     */
    public changeLanguage(language: LanguageKeys): void {
        if (language === this.getCurrentLanguage()) {
            return;
        }
        this.cookieService.set('language', languages[language].path, { expires: 30, path: '/' });

        // firebase config
        // document.cookie = "firebase-country-override=ca";
        // document.cookie = "firebase-language-override=fr,en";
        this.cookieService.set('firebase-language-override', languages[language].path, {
            expires: 30,
            path: '/',
        });

        window.location.href = '/' + languages[language].path + this.router.url;
    }
}
