import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private readonly toastrService: ToastrService) {
        // nothing left to do
    }

    /**
     * this shows snackbars in a queue and automatically closes them
     * @param errorFeedback
     * @param sticky
     * @param details
     * @param title
     * @param informationLink
     * @param showCloseBtn
     * @param rawMessage
     * @param enableReport
     * @param sentryEventId only usable with errorFeedback = true
     */
    public showInfoEvent(
        title: string,
        errorFeedback: boolean,
        sticky = false,
        details: string = null,
        informationLink: string = null,
        showCloseBtn = false,
        rawMessage: unknown = null,
        enableReport = true,
        sentryEventId: string = null,
        showDashboardButton = false,
        closeDialog: () => void = null,
    ): void {
        // style is overwriten by .ngx-toastr in _mat-overwrite.scss

        if (errorFeedback) {
            this.toastrService.error(details, title, {
                // @ts-expect-error: custom attribute
                informationLink,
                rawMessage,
                sentryEventId,
                enableReport,
                closeButton: sticky,
                disableTimeOut: sticky,
                timeOut: sticky ? 0 : 10000,
                progressBar: !sticky,
            });
        } else {
            this.toastrService.info(
                details != null ? details : title,
                details != null ? title : '',
                {
                    // @ts-expect-error: custom attribute
                    informationLink,
                    rawMessage,
                    closeButton: showCloseBtn,
                    disableTimeOut: sticky,
                    timeOut: sticky ? 0 : 10000,
                    progressBar: !sticky,
                    showDashboardButton: showDashboardButton,
                    closeDialog,
                },
            );
        }
    }
}
