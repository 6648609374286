/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GeoJsonExample } from '../models/geo-json-example';
import { GeocoderExtentEwkb } from '../models/geocoder-extent-ewkb';
import { GjFeatureCollectionEmployees } from '../models/gj-feature-collection-employees';

@Injectable({
  providedIn: 'root',
})
export class DebugControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation geocoderExtent
   */
  static readonly GeocoderExtentPath = '/debug/{accessCode}/ge.geojson';

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtent()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeoJsonExample>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentPath, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeoJsonExample>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<Array<GeoJsonExample>> {

    return this.geocoderExtent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonExample>>) => r.body as Array<GeoJsonExample>)
    );
  }

  /**
   * Path part for operation geocoderExtent1
   */
  static readonly GeocoderExtent1Path = '/d/{accessCode}/ge.geojson';

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtent1()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent1$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeoJsonExample>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtent1Path, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeoJsonExample>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtent1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent1(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<Array<GeoJsonExample>> {

    return this.geocoderExtent1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonExample>>) => r.body as Array<GeoJsonExample>)
    );
  }

  /**
   * Path part for operation geocoderExtent2
   */
  static readonly GeocoderExtent2Path = '/d/{accessCode}/geocoderExtent.geojson';

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtent2()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent2$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeoJsonExample>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtent2Path, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeoJsonExample>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtent2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent2(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<Array<GeoJsonExample>> {

    return this.geocoderExtent2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonExample>>) => r.body as Array<GeoJsonExample>)
    );
  }

  /**
   * Path part for operation geocoderExtent3
   */
  static readonly GeocoderExtent3Path = '/debug/{accessCode}/geocoderExtent.geojson';

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtent3()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent3$Response(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeoJsonExample>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtent3Path, 'get');
    if (params) {
      rb.path('accessCode', params.accessCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeoJsonExample>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as geojson.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtent3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtent3(params: {
    accessCode: string;
    context?: HttpContext
  }
): Observable<Array<GeoJsonExample>> {

    return this.geocoderExtent3$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonExample>>) => r.body as Array<GeoJsonExample>)
    );
  }

  /**
   * Path part for operation geocoderExtentWkbh
   */
  static readonly GeocoderExtentWkbhPath = '/debug/geocoderExtent.ewkb';

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentWkbh()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeocoderExtentEwkb>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentWkbhPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/text',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeocoderExtentEwkb>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentWkbh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh(params?: {
    context?: HttpContext
  }
): Observable<Array<GeocoderExtentEwkb>> {

    return this.geocoderExtentWkbh$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeocoderExtentEwkb>>) => r.body as Array<GeocoderExtentEwkb>)
    );
  }

  /**
   * Path part for operation geocoderExtentWkbh1
   */
  static readonly GeocoderExtentWkbh1Path = '/debug/ge.ewkb';

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentWkbh1()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeocoderExtentEwkb>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentWkbh1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/text',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeocoderExtentEwkb>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentWkbh1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh1(params?: {
    context?: HttpContext
  }
): Observable<Array<GeocoderExtentEwkb>> {

    return this.geocoderExtentWkbh1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeocoderExtentEwkb>>) => r.body as Array<GeocoderExtentEwkb>)
    );
  }

  /**
   * Path part for operation geocoderExtentWkbh2
   */
  static readonly GeocoderExtentWkbh2Path = '/d/geocoderExtent.ewkb';

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentWkbh2()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh2$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeocoderExtentEwkb>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentWkbh2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/text',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeocoderExtentEwkb>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentWkbh2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh2(params?: {
    context?: HttpContext
  }
): Observable<Array<GeocoderExtentEwkb>> {

    return this.geocoderExtentWkbh2$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeocoderExtentEwkb>>) => r.body as Array<GeocoderExtentEwkb>)
    );
  }

  /**
   * Path part for operation geocoderExtentWkbh3
   */
  static readonly GeocoderExtentWkbh3Path = '/d/ge.ewkb';

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentWkbh3()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh3$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GeocoderExtentEwkb>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentWkbh3Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/text',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeocoderExtentEwkb>>;
      })
    );
  }

  /**
   * get the extent of the geocoder as ewkb.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentWkbh3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentWkbh3(params?: {
    context?: HttpContext
  }
): Observable<Array<GeocoderExtentEwkb>> {

    return this.geocoderExtentWkbh3$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeocoderExtentEwkb>>) => r.body as Array<GeocoderExtentEwkb>)
    );
  }

  /**
   * Path part for operation geocoderExtentRedirect
   */
  static readonly GeocoderExtentRedirectPath = '/d/geocoderExtent';

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentRedirect()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentRedirectPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentRedirect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.geocoderExtentRedirect$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation geocoderExtentRedirect1
   */
  static readonly GeocoderExtentRedirect1Path = '/debug/geocoderExtent';

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentRedirect1()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentRedirect1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentRedirect1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect1(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.geocoderExtentRedirect1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation geocoderExtentRedirect2
   */
  static readonly GeocoderExtentRedirect2Path = '/d/ge';

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentRedirect2()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect2$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentRedirect2Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentRedirect2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect2(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.geocoderExtentRedirect2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation geocoderExtentRedirect3
   */
  static readonly GeocoderExtentRedirect3Path = '/debug/ge';

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geocoderExtentRedirect3()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect3$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GeocoderExtentRedirect3Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show the extent of the geocoder in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `geocoderExtentRedirect3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geocoderExtentRedirect3(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.geocoderExtentRedirect3$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getEmployeesOfCompanyAsGeoJson
   */
  static readonly GetEmployeesOfCompanyAsGeoJsonPath = '/debug/cl/{companyLocationId}/{accessCode}/emps.geojson';

  /**
   * get employees data of company location as geojson.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeesOfCompanyAsGeoJson()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesOfCompanyAsGeoJson$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;

    /**
     * tolerance in meters of within function between the company location and time and distance table destination point (to_point)
     */
    tolerance?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GjFeatureCollectionEmployees>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GetEmployeesOfCompanyAsGeoJsonPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('accessCode', params.accessCode, {});
      rb.query('tolerance', params.tolerance, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GjFeatureCollectionEmployees>>;
      })
    );
  }

  /**
   * get employees data of company location as geojson.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeesOfCompanyAsGeoJson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesOfCompanyAsGeoJson(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;

    /**
     * tolerance in meters of within function between the company location and time and distance table destination point (to_point)
     */
    tolerance?: number;
    context?: HttpContext
  }
): Observable<Array<GjFeatureCollectionEmployees>> {

    return this.getEmployeesOfCompanyAsGeoJson$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GjFeatureCollectionEmployees>>) => r.body as Array<GjFeatureCollectionEmployees>)
    );
  }

  /**
   * Path part for operation getEmployeesOfCompanyAsGeoJson1
   */
  static readonly GetEmployeesOfCompanyAsGeoJson1Path = '/d/cl/{companyLocationId}/{accessCode}/emps.geojson';

  /**
   * get employees data of company location as geojson.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeesOfCompanyAsGeoJson1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesOfCompanyAsGeoJson1$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;

    /**
     * tolerance in meters of within function between the company location and time and distance table destination point (to_point)
     */
    tolerance?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GjFeatureCollectionEmployees>>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GetEmployeesOfCompanyAsGeoJson1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('accessCode', params.accessCode, {});
      rb.query('tolerance', params.tolerance, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GjFeatureCollectionEmployees>>;
      })
    );
  }

  /**
   * get employees data of company location as geojson.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeesOfCompanyAsGeoJson1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeesOfCompanyAsGeoJson1(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    accessCode: string;

    /**
     * tolerance in meters of within function between the company location and time and distance table destination point (to_point)
     */
    tolerance?: number;
    context?: HttpContext
  }
): Observable<Array<GjFeatureCollectionEmployees>> {

    return this.getEmployeesOfCompanyAsGeoJson1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GjFeatureCollectionEmployees>>) => r.body as Array<GjFeatureCollectionEmployees>)
    );
  }

  /**
   * Path part for operation redirectToEmpsView
   */
  static readonly RedirectToEmpsViewPath = '/d/cl/{companyLocationId}/viewemps';

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `redirectToEmpsView()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.RedirectToEmpsViewPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `redirectToEmpsView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.redirectToEmpsView$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation redirectToEmpsView1
   */
  static readonly RedirectToEmpsView1Path = '/debug/cl/{companyLocationId}/viewemps';

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `redirectToEmpsView1()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView1$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.RedirectToEmpsView1Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `redirectToEmpsView1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView1(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.redirectToEmpsView1$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation redirectToEmpsView2
   */
  static readonly RedirectToEmpsView2Path = '/d/cl/{companyLocationId}/emps';

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `redirectToEmpsView2()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView2$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.RedirectToEmpsView2Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `redirectToEmpsView2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView2(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.redirectToEmpsView2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation redirectToEmpsView3
   */
  static readonly RedirectToEmpsView3Path = '/debug/cl/{companyLocationId}/emps';

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `redirectToEmpsView3()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView3$Response(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.RedirectToEmpsView3Path, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * show employees data of company location in geojson.io.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `redirectToEmpsView3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  redirectToEmpsView3(params: {
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.redirectToEmpsView3$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAccessCode
   */
  static readonly GetAccessCodePath = '/debug/accessCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessCode$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GetAccessCodePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessCode(params?: {
    context?: HttpContext
  }
): Observable<{
}> {

    return this.getAccessCode$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getAccessCode1
   */
  static readonly GetAccessCode1Path = '/d/accessCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessCode1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessCode1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.GetAccessCode1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessCode1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessCode1(params?: {
    context?: HttpContext
  }
): Observable<{
}> {

    return this.getAccessCode1$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation overview
   */
  static readonly OverviewPath = '/debug';

  /**
   * get content overview of debug controller in html.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `overview()` instead.
   *
   * This method doesn't expect any request body.
   */
  overview$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.OverviewPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get content overview of debug controller in html.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `overview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  overview(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.overview$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation overview1
   */
  static readonly Overview1Path = '/d';

  /**
   * get content overview of debug controller in html.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `overview1()` instead.
   *
   * This method doesn't expect any request body.
   */
  overview1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DebugControllerImplService.Overview1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/html',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * get content overview of debug controller in html.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `overview1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  overview1(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.overview1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
