import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SentryReportService } from '@shared/services/sentry-report.service';
import { FeedbackDialogTranslations } from '@shared/translations/feedback-dialog-translations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: '[feedback]',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({ opacity: 0 })),
            state('active', style({ opacity: 1 })),
            state('removed', style({ opacity: 0 })),
            transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
            transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
        ]),
    ],
    preserveWhitespaces: false,
})
export class FeedbackComponent extends Toast {
    public informationLink: string;

    public rawMessage:
        | string
        | { [data: string]: unknown }
        | { [data: string]: unknown; code: string };

    public sentryEventId: string;

    public rawMessageIsJson: boolean;

    public expandRawMessage = false;

    public translations: typeof FeedbackDialogTranslations = FeedbackDialogTranslations;

    public enableReport = false;

    public showDashboardButton: boolean;

    public closeDialog: () => void;

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
        private readonly sentryReportService: SentryReportService,
        private router: Router,
    ) {
        super(toastrService, toastPackage);

        // @ts-expect-error: informationLink not in default ToastPackage
        this.informationLink = toastPackage.config.informationLink;
        // @ts-expect-error: rawMessage not in default ToastPackage
        this.rawMessage = toastPackage.config.rawMessage;
        // @ts-expect-error: rawMessage not in default ToastPackage
        this.enableReport = toastPackage.config.enableReport;
        // @ts-expect-error: showDashboardButton not in default ToastPackage
        this.showDashboardButton = toastPackage.config.showDashboardButton;
        // @ts-expect-error: showDashboardButton not in default ToastPackage
        this.closeDialog = toastPackage.config.closeDialog;

        this.rawMessageIsJson = !(
            typeof this.rawMessage === 'string' || this.rawMessage instanceof String
        );
    }

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        if (this.enableReport) {
            this.expandRawMessage = true;
            this.toastPackage.config.closeButton = true;
            this.toastPackage.config.tapToDismiss = false;
            this.toastPackage.config.disableTimeOut = true;
        }
    }

    public reportError(): void {
        if (!this.sentryEventId) {
            this.sentryEventId = this.sentryReportService.reportError(
                !this.rawMessage ? this.message : this.rawMessage,
            );
        }
        this.sentryReportService.reportUserError(this.sentryEventId);
    }

    public onGoToDashboardClick(): void {
        if (this.closeDialog) {
            this.closeDialog();
        }
        void this.router.navigate(['/audits']);
    }
}
