import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { RouterModule } from '@angular/router';
import { CalloutComponent } from '@shared/components/callout/callout.component';
import { HeaderLessTabsDirective } from '@shared/directives/header-less-tabs.directive';
import { NumberRangeDirective } from '@shared/directives/number-range.directive';
import { PopoverSelectDirective } from '@shared/directives/popover-select.directive';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { FormGroupPipe } from '@shared/pipes/form-group.pipe';
import { CustomRouteReuseStrategyService } from '@shared/services/custom-route-reuse-strategy.service';
import { NgxMasonryModule } from 'ngx-masonry';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { CompanyLocationHeaderComponent } from './components/company-location-header/company-location-header.component';
import { CompanyLocationHeaderDropdownsComponent } from './components/company-location-header-dropdowns/company-location-header-dropdowns.component';
import { ExpandableSectionComponent } from './components/expandable-section/expandable-section.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HelpPopoverComponent } from './components/help-popover/help-popover.component';
import { InfoComponent } from './components/info/info.component';
import { KpiMeasureComponent } from './components/kpi-measure/kpi-measure.component';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageTabsComponent } from './components/page-tabs/page-tabs.component';
import { UpscoreSimpleTableComponent } from './components/upscore-simple-table/upscore-simple-table.component';
import { UpscoreSliderComponent } from './components/upscore-slider/upscore-slider.component';
import { UpscoreSliderInputComponent } from './components/upscore-slider-input/upscore-slider-input.component';
import { UpscoreTableComponent } from './components/upscore-table/upscore-table.component';
import { ErrorCancelContinueDialogComponent } from './dialogs/error-cancel-continue-dialog/error-cancel-continue-dialog.component';
import { InputNumberRangeDirective } from './directives/input-number-range.directive';
import { PopoverHoverDirective } from './directives/popover-hover.directive';
import { MaterialModule } from './modules/material/material.module';
import { DailyKpiPipe } from './pipes/daily-kpi.pipe';
import { PercentageChangePipe } from './pipes/percentage-change.pipe';

@NgModule({
    declarations: [
        UpscoreSliderComponent,
        PageTabsComponent,
        PageTabsComponent,
        FeedbackComponent,
        DailyKpiPipe,
        KpiMeasureComponent,
        PercentageChangePipe,
        FormControlPipe,
        FormGroupPipe,
        InfoComponent,
        ExpandableSectionComponent,
        LanguageSwitchComponent,
        CompanyLocationHeaderComponent,
        NotFoundComponent,
        UpscoreSimpleTableComponent,
        UpscoreTableComponent,
        UpscoreSliderInputComponent,
        PopoverSelectDirective,
        CompanyLocationHeaderDropdownsComponent,
        HeaderLessTabsDirective,
        UpscoreSimpleTableComponent,
        HelpPopoverComponent,
        PopoverHoverDirective,
        CalloutComponent,
        NumberRangeDirective,
        InputNumberRangeDirective,
        ErrorCancelContinueDialogComponent,
    ],
    providers: [
        DecimalPipe,
        // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategyService },
    ],
    imports: [
        ToastrModule.forRoot({
            toastComponent: FeedbackComponent,
            preventDuplicates: true,
            countDuplicates: true,
        }),
        CommonModule,
        HttpClientModule,
        RouterModule,
        MaterialModule,
        CdkStepperModule,
        NgxMasonryModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        CarouselModule,
    ],
    exports: [
        RouterModule,
        UpscoreSliderComponent,
        MaterialModule,
        CdkStepperModule,
        CommonModule,
        PageTabsComponent,
        NgxMasonryModule,
        DailyKpiPipe,
        KpiMeasureComponent,
        PercentageChangePipe,
        InfoComponent,
        ExpandableSectionComponent,
        LanguageSwitchComponent,
        CompanyLocationHeaderComponent,
        UpscoreSimpleTableComponent,
        UpscoreTableComponent,
        UpscoreSliderInputComponent,
        PopoverSelectDirective,
        CompanyLocationHeaderDropdownsComponent,
        HeaderLessTabsDirective,
        NumberRangeDirective,
        FormControlPipe,
        FormGroupPipe,
        HelpPopoverComponent,
        PopoverHoverDirective,
        CalloutComponent,
        InputNumberRangeDirective,
        ErrorCancelContinueDialogComponent,
    ],
})
export class SharedModule {}
