/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Package } from '../models/package';
import { PackageResponse } from '../models/package-response';
import { PackageToChange } from '../models/package-to-change';

@Injectable({
  providedIn: 'root',
})
export class PackagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPackage
   */
  static readonly GetPackagePath = '/api/v1/packages/{packageId}';

  /**
   * Get a specific mobility package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackage$Response(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.GetPackagePath, 'get');
    if (params) {
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * Get a specific mobility package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPackage(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<PackageResponse> {

    return this.getPackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation replacePackage
   */
  static readonly ReplacePackagePath = '/api/v1/packages/{packageId}';

  /**
   * replace mobility package.
   *
   * The process can be asynchronous or not based on what you pass in the async parameter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replacePackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replacePackage$Response(params: {

    /**
     * async
     */
    async?: boolean;

    /**
     * package id
     */
    packageId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: Package
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.ReplacePackagePath, 'put');
    if (params) {
      rb.query('async', params.async, {});
      rb.path('packageId', params.packageId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * replace mobility package.
   *
   * The process can be asynchronous or not based on what you pass in the async parameter.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `replacePackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replacePackage(params: {

    /**
     * async
     */
    async?: boolean;

    /**
     * package id
     */
    packageId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: Package
  }
): Observable<PackageResponse> {

    return this.replacePackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation deletePackage
   */
  static readonly DeletePackagePath = '/api/v1/packages/{packageId}';

  /**
   * Delete a mobility package.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePackage$Response(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.DeletePackagePath, 'delete');
    if (params) {
      rb.path('packageId', params.packageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete a mobility package.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePackage(params: {

    /**
     * package id
     */
    packageId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.deletePackage$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updatePackage
   */
  static readonly UpdatePackagePath = '/api/v1/packages/{packageId}';

  /**
   * update mobility package.
   *
   * The process can be asynchronous or not based on what you pass in the async parameter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePackage$Response(params: {

    /**
     * async
     */
    async?: boolean;

    /**
     * package id
     */
    packageId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: PackageToChange
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.UpdatePackagePath, 'patch');
    if (params) {
      rb.query('async', params.async, {});
      rb.path('packageId', params.packageId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * update mobility package.
   *
   * The process can be asynchronous or not based on what you pass in the async parameter.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePackage(params: {

    /**
     * async
     */
    async?: boolean;

    /**
     * package id
     */
    packageId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: PackageToChange
  }
): Observable<PackageResponse> {

    return this.updatePackage$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

  /**
   * Path part for operation companyLocationPackages
   */
  static readonly CompanyLocationPackagesPath = '/api/v1/locations/{companyLocationId}/packages';

  /**
   * See all mobility packages for a company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationPackages()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationPackages$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PackageResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.CompanyLocationPackagesPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PackageResponse>>;
      })
    );
  }

  /**
   * See all mobility packages for a company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationPackages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationPackages(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<Array<PackageResponse>> {

    return this.companyLocationPackages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PackageResponse>>) => r.body as Array<PackageResponse>)
    );
  }

  /**
   * Path part for operation newCompanyLocationPackageRequest
   */
  static readonly NewCompanyLocationPackageRequestPath = '/api/v1/locations/{companyLocationId}/packages';

  /**
   * Create new mobility packages.
   *
   * The process can be asynchronous or not based on what you pass in the async parameter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyLocationPackageRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyLocationPackageRequest$Response(params: {

    /**
     * async
     */
    async?: boolean;

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: Package
  }
): Observable<StrictHttpResponse<PackageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagesService.NewCompanyLocationPackageRequestPath, 'post');
    if (params) {
      rb.query('async', params.async, {});
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('withDistanceCalculation', params.withDistanceCalculation, {});
      rb.query('detailedTrip', params.detailedTrip, {});
      rb.query('detailedTripType', params.detailedTripType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageResponse>;
      })
    );
  }

  /**
   * Create new mobility packages.
   *
   * The process can be asynchronous or not based on what you pass in the async parameter.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newCompanyLocationPackageRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyLocationPackageRequest(params: {

    /**
     * async
     */
    async?: boolean;

    /**
     * company location id
     */
    companyLocationId: number;

    /**
     * Allow distance calculation
     */
    withDistanceCalculation?: boolean;

    /**
     * calculate trip distance individually
     */
    detailedTrip?: boolean;

    /**
     * transportLegs processing type
     */
    detailedTripType?: 'ENTRY_ONLY' | 'ENTRY_EXIT' | 'ENTRY_EXIT_WITH_UNDEFINED';
    context?: HttpContext
    body: Package
  }
): Observable<PackageResponse> {

    return this.newCompanyLocationPackageRequest$Response(params).pipe(
      map((r: StrictHttpResponse<PackageResponse>) => r.body as PackageResponse)
    );
  }

}
