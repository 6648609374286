import { Injectable } from '@angular/core';
import { EditEmployee } from '@shared/interfaces/edit-employee.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    AggregatedEmployeeInput,
    AggregatedEstimationQuality,
    AggregatedGeocodingResult,
    DetailedEmployeeInput,
    DetailedGeocodingResult,
    Employee,
    EmployeeIdAndLocationAccuracy,
    ValidationResult,
} from 'src/app/api/models';
import { EmployeePut } from 'src/app/api/models/employee-put';
import { EmployeeUploadService, EmployeesService } from '../../api/services';

@Injectable({
    providedIn: 'root',
})
export class EmployeesWrapperService {
    public employeeDialogInfo$: BehaviorSubject<EditEmployee> = new BehaviorSubject(null);
    constructor(
        private employeesService: EmployeesService,
        private employeeUploadService: EmployeeUploadService,
    ) {
        // nothing to do here
    }

    /**
     * Uploads aggregated employee csv data for provided location
     * @param params
     */
    public uploadAggregatedEmployeeDataForLocation(params: {
        companyLocationId: number;
        body: { file: Blob };
        geocoder: 'employeeGeocoder' | 'hereApi';
        withDistanceCalculation?: boolean;
        overwriteExistingEmployees?: boolean;
        limitDistanceFromCompanyLocation?: number;
    }): Observable<AggregatedGeocodingResult> {
        return this.employeeUploadService.uploadEmployees({
            ...params,
            mode: 'AGGREGATED',
        }) as Observable<AggregatedGeocodingResult>;
    }

    /**
     * Uploads aggregated employee json data for provided location
     * @param params
     */
    public uploadAggregatedEmployeeDataForLocationAsJson(params: {
        companyLocationId: number;
        body: AggregatedEmployeeInput[];
        withDistanceCalculation?: boolean;
        limitDistanceFromCompanyLocation?: number;
    }): Observable<AggregatedGeocodingResult> {
        return this.employeeUploadService.uploadJsonEmployees({
            ...params,
            mode: 'AGGREGATED',
        }) as Observable<AggregatedGeocodingResult>;
    }

    /**
     * Downloads aggregated employee csv data for provided location
     * @param companyLocationId
     */
    public downloadAggregatedEmployeeDataForLocation(
        companyLocationId: number,
    ): Observable<string> {
        return this.employeesService.getAggregatedEmployeesAsCsvFile({
            companyLocationId,
        });
    }

    /**
     * Downloads detailed employee csv data for provided location
     * @param companyLocationId
     */
    public downloadDetailedEmployeeDataForLocation(companyLocationId: number): Observable<string> {
        return this.employeesService.getDetailedEmployeesAsCsvFile({
            companyLocationId,
        });
    }

    /**
     * Uploads detailed employee csv data for provided location
     * @param params
     */
    public uploadDetailedEmployeeDataForLocationAsJson(params: {
        companyLocationId: number;
        geocoder: 'employeeGeocoder' | 'hereApi';
        body: DetailedEmployeeInput[];
        withDistanceCalculation?: boolean;
        limitDistanceFromCompanyLocation?: number;
    }): Observable<DetailedGeocodingResult> {
        return this.employeeUploadService.uploadJsonEmployees({
            ...params,
            mode: 'DETAILED',
        }) as Observable<DetailedGeocodingResult>;
    }

    /**
     * Uploads detailed employee json data for provided location
     * @param params
     */
    public uploadDetailedEmployeeDataForLocation(params: {
        companyLocationId: number;
        geocoder: 'employeeGeocoder' | 'hereApi';
        body: { file: Blob };
        withDistanceCalculation?: boolean;
        overwriteExistingEmployees?: boolean;
        limitDistanceFromCompanyLocation?: number;
    }): Observable<DetailedGeocodingResult> {
        return this.employeeUploadService.uploadEmployees({
            ...params,
            mode: 'DETAILED',
        }) as Observable<DetailedGeocodingResult>;
    }

    /**
     * Validates the uploaded file and returns error in case of missing columns
     * @param params
     */
    public validateUploadedEmployeeData(params: {
        mode: 'AGGREGATED' | 'DETAILED';
        ignored: string;
        body: { file: Blob };
    }): Observable<ValidationResult> {
        return this.employeeUploadService.validateFile({
            ...params,
        }) as Observable<ValidationResult>;
    }

    /**
     * Method to get the estimated employee locations
     * @param locationId
     */
    public getEmployeeLocations(locationId: number): Observable<Employee[]> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.employeesService.employeeLocations(params);
    }

    /**
     * Method to estimate employee locations
     * @param locationId
     * @param employees
     * @param withDistanceCalculation
     */
    public estimateEmployees(
        locationId: number,
        employees: number,
        withDistanceCalculation = true,
    ): Observable<Employee[]> {
        const params: {
            companyLocationId: number;
            count: number;
            withDistanceCalculation: boolean;
        } = {
            companyLocationId: locationId,
            count: employees,
            withDistanceCalculation,
        };

        return this.employeesService.estimateEmployeesBasedOnCommuterData(params);
    }

    /**
     * Method to get detailed employee estimation quality for a company location
     * @param locationId
     */
    public getDetailedEstimationQuality(
        locationId: number,
    ): Observable<EmployeeIdAndLocationAccuracy[]> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.employeesService.detailedEstimationQuality(params);
    }

    /**
     * Method to get aggregated employee estimation quality for a company location
     * @param locationId
     */
    public getAggregatedEstimationQuality(
        locationId: number,
    ): Observable<AggregatedEstimationQuality[]> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.employeesService.aggregatedEstimationQuality(params);
    }

    /**
     * Method to update a specific employee's data
     * @param id
     * @param companyLocationId
     * @param body
     */
    public updateEmployee(params: {
        id: number;
        companyLocationId: number;
        body: EmployeePut;
    }): Observable<Employee> {
        return this.employeesService.putEmployee(params);
    }
}
