import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginGuard implements CanLoad {
    constructor(private readonly authService: AuthService, private readonly router: Router) {
        // nothing to do here
    }

    /**
     * Determines whether to load a not auth protected lazy loaded module
     * @param route
     * @param segments
     */
    public canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isLoggedOut = !this.authService.isLoggedIn();
        if (isLoggedOut) {
            return true;
        }

        void this.router.navigate(['/audits']);

        return false;
    }
}
