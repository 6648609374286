import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router, RouterModule } from '@angular/router';
import {
    BrowserTracing,
    Replay,
    TraceService,
    createErrorHandler,
    init,
    routingInstrumentation,
} from '@sentry/angular-ivy';
import { DENY_URLS, IGNORE_ERRORS } from '@shared/constants/sentry-ignore-errors.constant';
import { JwtInterceptor } from '@shared/interceptors/jwt.interceptor';
import { LoadingInterceptor } from '@shared/interceptors/loading.interceptor';
import { ResponseErrorInterceptor } from '@shared/interceptors/response-error.interceptor';
import { SharedModule } from '@shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import './shared/prototypes/observables-protoypes';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';

init({
    dsn: environment.sentryDsn,
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: DENY_URLS,
    ignoreErrors: IGNORE_ERRORS,
    integrations: [
        new BrowserTracing({
            tracePropagationTargets: [
                'localhost',
                'https://upscore-staging.a.triply.ai',
                'https://upscore.a.triply.ai',
            ],
            routingInstrumentation: routingInstrumentation,
        }),
        new Replay(),
    ],
});

@NgModule({
    declarations: [AppComponent, LoadingIndicatorComponent],
    imports: [
        ApiModule.forRoot({ rootUrl: `${environment.apiUrl}` }),
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        BrowserModule,
        CommonModule,
        HttpClientModule,
        SharedModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseErrorInterceptor, multi: true },
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(success => console.log(`Bootstrap success`))
    .catch(err => console.error(err));
