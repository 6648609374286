/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class DistanceAndTimeBatchCalculationControllerImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation newCompanyLocationBatch
   */
  static readonly NewCompanyLocationBatchPath = '/api/v1/distances';

  /**
   * create distance and time matrix between origin and destination points and save results as csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyLocationBatch()` instead.
   *
   * This method sends `text/csv;charset=UTF-8` and handles request body of type `text/csv;charset=UTF-8`.
   */
  newCompanyLocationBatch$Response(params: {
    modes: Array<string>;
    time?: string;
    context?: HttpContext
    body: string
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DistanceAndTimeBatchCalculationControllerImplService.NewCompanyLocationBatchPath, 'post');
    if (params) {
      rb.query('modes', params.modes, {});
      rb.query('time', params.time, {});
      rb.body(params.body, 'text/csv;charset=UTF-8');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * create distance and time matrix between origin and destination points and save results as csv.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newCompanyLocationBatch$Response()` instead.
   *
   * This method sends `text/csv;charset=UTF-8` and handles request body of type `text/csv;charset=UTF-8`.
   */
  newCompanyLocationBatch(params: {
    modes: Array<string>;
    time?: string;
    context?: HttpContext
    body: string
  }
): Observable<void> {

    return this.newCompanyLocationBatch$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
