import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Package, PackageResponse } from '../../api/models';
import { PackagesService } from '../../api/services';
import { StrictHttpResponse } from '../../api/strict-http-response';

@Injectable({
    providedIn: 'root',
})
export class MobilityPackagesWrapperService {
    constructor(private mobilityPackagesService: PackagesService) {
        // nothing left to do
    }

    /**
     * Get mobility packages for company location
     * @param locationId
     */
    public getMobilityPackages(locationId: number): Observable<PackageResponse[]> {
        return this.mobilityPackagesService.companyLocationPackages({
            companyLocationId: locationId,
        });
    }

    /**
     * create mobility package
     * @param _package
     * @param id
     */
    public createMobilityPackage(_package: Package, id: number): Observable<PackageResponse> {
        return this.mobilityPackagesService.newCompanyLocationPackageRequest({
            companyLocationId: id,
            body: _package,
        });
    }

    /**
     * update mobility package
     * @param postedPackage
     * @param packageId
     */
    public updateMobilityPackage(
        postedPackage: Package,
        packageId: number,
    ): Observable<PackageResponse> {
        return this.mobilityPackagesService.updatePackage({
            packageId,
            body: postedPackage,
        });
    }

    /**
     * delete a mobility package
     * @param packageId
     */
    public deleteMobilityPackage(packageId: number): Observable<StrictHttpResponse<{}>> {
        return this.mobilityPackagesService.deletePackage$Response({ packageId });
    }
}
