import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CatchmentGeoJson,
    CompanyLocation,
    CompanyLocationToChange,
    DepartmentKpi,
    MinimalMobilityScore,
    MobilityScore,
    MobilityStats,
    PostedCompanyLocation,
} from 'src/app/api/models';
import { CompanyLocationsService } from '../../api/services';
import { OptimalCatchment } from '../interfaces/optimal-catchment-response.interface';

@Injectable({
    providedIn: 'root',
})
export class CompanyLocationsWrapperService {
    constructor(private companyLocationsService: CompanyLocationsService) {
        // nothing to do here
    }

    /**
     * Method to post new locations
     * @params
     */
    public createLocation(params?: { body: PostedCompanyLocation }): Observable<CompanyLocation> {
        return this.companyLocationsService.newCompanyLocation(params);
    }

    /**
     * Method to patch location
     * @params
     */
    public updateLocation(params?: {
        companyLocationId: number;
        body: CompanyLocationToChange;
    }): Observable<CompanyLocation> {
        return this.companyLocationsService.updateCompanyLocation(params);
    }

    /**
     * Method to get all company locations
     */
    public getAllCompanyLocations(): Observable<CompanyLocation[]> {
        return this.companyLocationsService.allCompanyLocations();
    }

    /**
     * Method to get company location with a provided id
     * @param locationId
     */
    public getCompanyLocation(locationId: number): Observable<CompanyLocation> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.companyLocation(params);
    }

    /**
     * Method to delete company location with a provided id
     * @param locationId
     */
    public deleteCompanyLocation(locationId: number): Observable<string> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.deleteCompanyLocation(params);
    }

    /**
     * Method to get the catchment area for transport modes
     * @param params
     */
    public getCatchmentArea(params: {
        companyLocationId: number;
        // mode: 'bike' | 'walk' | 'car' | 'transit' | 'carpooling' | 'transit_and_bike';
        mode: 'bike' | 'walk' | 'car' | 'transit';
        times: number[];
        entranceNumber?: number;
    }): Observable<CatchmentGeoJson> {
        return this.companyLocationsService.companyLocationCatchmentRequest(params);
    }

    /**
     * Method to get the mobility score (current + optimal)
     * @param locationId
     */
    public getMobilityScore(
        locationId: number,
        forceDistanceRecalculation: boolean,
        forceScoreRecalculation: boolean,
    ): Observable<{
        current?: MobilityScore;
        optimal?: MobilityScore;
        currentDepartmentKpis?: DepartmentKpi[];
        optimalDepartmentKpis?: DepartmentKpi[];
        forceDistanceRecalculation?: boolean;
        forceScoreRecalculation?: boolean;
    }> {
        const params: {
            companyLocationId: number;
            forceDistanceRecalculation?: boolean;
            forceScoreRecalculation?: boolean;
        } = {
            companyLocationId: locationId,
            forceDistanceRecalculation,
            forceScoreRecalculation,
        };

        return this.companyLocationsService.companyLocationMobilityScoreRequest(params);
    }

    /**
     * Method to get the optimal catchment area
     * @param locationId
     * @param entranceNumber
     */
    public getOptimalCatchment(
        locationId: number,
        entranceNumber: number,
    ): Observable<OptimalCatchment> {
        const params: { companyLocationId: number; entranceNumber: number } = {
            companyLocationId: locationId,
            entranceNumber,
        };

        return this.companyLocationsService
            .getOptimalCatchment(params)
            .pipe(map(res => res as unknown as OptimalCatchment));
    }

    /**
     * Method to get the suggested modal split
     * @param locationId
     */
    public getSuggestedModalSplit(locationId: number): Observable<MobilityStats> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.companyLocationModalSplitSuggestions(params);
    }

    /**
     * Method to get the minimal mobility score (current + optimal)
     * @param locationId
     */
    public getMinimalMobilityScore(
        locationId: number,
        forceDistanceRecalculation?: boolean,
        forceScoreRecalculation?: boolean,
    ): Observable<{
        current?: MinimalMobilityScore;
        optimal?: MinimalMobilityScore;
        forceDistanceRecalculation?: boolean;
        forceScoreRecalculation?: boolean;
    }> {
        const params: {
            companyLocationId: number;
            forceDistanceRecalculation?: boolean;
            forceScoreRecalculation?: boolean;
        } = {
            companyLocationId: locationId,
            forceDistanceRecalculation,
            forceScoreRecalculation,
        };

        return this.companyLocationsService.companyLocationMinimalMobilityScoreRequest(params);
    }
}
