/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { FeatureFlagsService } from './services/feature-flags.service';
import { MobilityPropertiesService } from './services/mobility-properties.service';
import { PackagesService } from './services/packages.service';
import { EmployeesService } from './services/employees.service';
import { ServiceAreaService } from './services/service-area.service';
import { TrafficZoneService } from './services/traffic-zone.service';
import { CompanyLocationImportExportControllerImplService } from './services/company-location-import-export-controller-impl.service';
import { MobilityScoreDebugControllerService } from './services/mobility-score-debug-controller.service';
import { ModeEstimationService } from './services/mode-estimation.service';
import { UserService } from './services/user.service';
import { CompanyLocationsService } from './services/company-locations.service';
import { EmployeeUploadService } from './services/employee-upload.service';
import { SharingService } from './services/sharing.service';
import { ImprovementsService } from './services/improvements.service';
import { GeocodingControllerService } from './services/geocoding-controller.service';
import { DistanceAndTimeBatchCalculationControllerImplService } from './services/distance-and-time-batch-calculation-controller-impl.service';
import { DebugControllerImplService } from './services/debug-controller-impl.service';
import { UserControllerService } from './services/user-controller.service';
import { PublicTransportCostService } from './services/public-transport-cost.service';
import { DuplicateUserService } from './services/duplicate-user.service';
import { CombineCompaniesService } from './services/combine-companies.service';
import { CarpoolingDebugControllerImplService } from './services/carpooling-debug-controller-impl.service';
import { EmployeeDebugControllerImplService } from './services/employee-debug-controller-impl.service';
import { TestNewMobilityScoreService } from './services/test-new-mobility-score.service';
import { TimezoneService } from './services/timezone.service';
import { TripsService } from './services/trips.service';
import { JobsService } from './services/jobs.service';
import { ExportService } from './services/export.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    FeatureFlagsService,
    MobilityPropertiesService,
    PackagesService,
    EmployeesService,
    ServiceAreaService,
    TrafficZoneService,
    CompanyLocationImportExportControllerImplService,
    MobilityScoreDebugControllerService,
    ModeEstimationService,
    UserService,
    CompanyLocationsService,
    EmployeeUploadService,
    SharingService,
    ImprovementsService,
    GeocodingControllerService,
    DistanceAndTimeBatchCalculationControllerImplService,
    DebugControllerImplService,
    UserControllerService,
    PublicTransportCostService,
    DuplicateUserService,
    CombineCompaniesService,
    CarpoolingDebugControllerImplService,
    EmployeeDebugControllerImplService,
    TestNewMobilityScoreService,
    TimezoneService,
    TripsService,
    JobsService,
    ExportService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
