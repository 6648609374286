import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StrictHttpResponse } from 'src/app/api/strict-http-response';
import { SimpleUser } from '../../api/models/simple-user';
import { UserInfo } from '../../api/models/user-info';
import { UserService } from '../../api/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class ApiWrapperService {
    constructor(private readonly userService: UserService) {
        // nothing to do here
    }

    /**
     * Login Post Request
     * @param username
     * @param password
     */
    public loginPost(username: string, password: string): Observable<StrictHttpResponse<UserInfo>> {
        const params: {
            body: SimpleUser;
        } = {
            body: {
                username,
                password,
            },
        };

        return this.userService.login$Response(params);
    }

    /**
     * UserInfo Get Request
     */
    public getUserInfo(): Observable<UserInfo> {
        return this.userService.userInfo();
    }
}
